/* ==========================================================================
   14.6 #HOME DARK - INTRO TEXT
   ========================================================================== */
.dark-intro-text {
   padding-top: 130px;
   text-align: center;

   @media (max-width: 991px) {
      padding-top: 70px;
   }
}

.dark-intro-text__title {
   padding: 0px;
   margin: 0px;
}

.dark-intro-text__description {
   line-height: 2;
   max-width: 820px;
   margin: 0px auto;
   padding: 38px 0px 0px 0px;

   @media (max-width: 991px) {
      padding: 30px 0px 0px 0px;
      line-height: 1.8;
   }
}