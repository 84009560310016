/* ==========================================================================
   11.2 #CANVAS CART
   ========================================================================== */
.canvas-cart {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   z-index: 999;
   visibility: hidden;
   pointer-events: none;
   transition: all 0.3s ease-in;
   -webkit-transition: all 0.3s ease-in;
   -moz-transition: all 0.3s ease-in;
   overflow: hidden;

   &.active {
      visibility: visible;
      pointer-events: auto;

      .canvas-cart__overlay {
         opacity: 1;
      }

      .canvas-cart__content {
         transform: none;
         -webkit-transform: none;
         -moz-transform: none;
      }
   }

   .header-cart__subtotal {
      border: none;
   }

   .subtotal__title {
      font-size: 20px;

      @media (max-width: 991px) {
         font-size: 16px;
      }
   }

   .subtotal__value {
      font-size: 24px;

      @media (max-width: 991px) {
         font-size: 16px;
      }
   }

   .header-cart__button {
      font-size: 16px;
      padding-top: 16px;
      padding-bottom: 16px;
      margin-top: 14px;
      background: #fff;
      transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;

      @media (max-width: 991px) {
         font-size: 13px;
         padding-top: 7px;
         padding-bottom: 7px;
      }

      &:hover {
         background: #000;
         border-color: #000;
         color: #fff;
      }
   }
}

.canvas-cart__overlay {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: rgba(0, 0, 0, 0.4);
   opacity: 0;
   transition: all 0.3s ease-in;
   -webkit-transition: all 0.3s ease-in;
   -moz-transition: all 0.3s ease-in;
}

.canvas-cart__content {
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   background: #fff;
   max-height: 100vh;
   overflow-y: scroll;
   z-index: 9999;
   width: 645px;
   max-width: 100%;
   padding: 0px;
   transform: translateX(100%);
   -webkit-transform: translateX(100%);
   -moz-transform: translateX(100%);
   transition: all 0.3s ease-in;
   -webkit-transition: all 0.3s ease-in;
   -moz-transition: all 0.3s ease-in;

   @media (max-width: 991px) {
      width: 500px;
   }

   &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      background: url(loader-2.svg?v=2) top center no-repeat;
      background-size: cover;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;
   }

   &.is-loading {

      &:before {
         opacity: 1;
      }

      > * {
         opacity: 0;
      }
   }

   .cart__empty {
      padding-top: 40vh;
   }
}

.canvas-cart__d-flex {
   padding: 50px 80px 75px 80px;
   min-height: 100vh;
   display: flex;
   flex-wrap: wrap;

   @media (max-width: 991px) {
      padding: 30px;
   }
}

.canvas-cart__top-and-products {
   width: 100%;
}

.canvas-cart__bottom {
   width: 100%;
   margin: auto 0px 0px 0px;
}

.canvas-cart__heading  {
   padding: 0px 0px 40px 0px;
   border-bottom: 1px solid #e9e9e9;
   margin-bottom: 70px;

   @media (max-width: 991px) {
      padding-bottom: 25px;
      margin-bottom: 30px;
   }
}

.canvas-cart__h3 {
   font-size: 24px;
   line-height: 1.2;
   font-family: "Pretendard";
   padding: 0px;
   margin: 0px;

   @media (max-width: 991px) {
      font-size: 18px;
   }
}

.canvas-cart__close {
   margin-left: auto;
   padding-left: 20px;
   text-align: right;
}

.canvas-cart__close--type-2 {
   margin: 20px;
   padding: 0px;
   text-align: left;
}

.cart-item__quantity {
   margin-left: auto;
   padding-left: 20px;

   @media (max-width: 991px) {
      display: none;
   }
}