/* ==========================================================================
   #HTML
   ========================================================================== */

html {
  font-size: 1rem;

  @media (max-width: 1199px) {
    font-size: 0.9rem;
  }

  @media (max-width: 991px) {
    font-size: 0.85rem;
  }

  @media (max-width: 767px) {
    font-size: 0.75rem;
  }
}