/* ==========================================================================
   #HEADER
   ========================================================================== */
.header {
   position: relative;
   z-index: 990;
}

/* Container */
.header__container  {
   padding-top: 65px;
   padding-bottom: 60px;
   border-bottom: 1px solid #c4c3bd;
   position: relative;

   @media (max-width: 991px) {
      padding-top: 18px;
      padding-bottom: 17px;
   }
}

.header__container--type-2 {
   border: none;
}

/* Mobile menu */
.header__mobile-menu {
   display: none;

   @media (max-width: 991px) {
      display: block;
   }
}

.mobile-menu__open {
   font-size: 20px;
   margin-right: 30px;

   a {
      color: #000;
   }
}

.mobile-menu {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   z-index: 99;
   pointer-events: none;
   visibility: hidden;
   display: none;
   transition: all 0.3s ease-in;
   -webkit-transition: all 0.3s ease-in;
   -moz-transition: all 0.3s ease-in;

   @media (max-width: 991px) {
      display: block;
   }

   &.active {
      pointer-events: auto;
      visibility: visible;

      .mobile-menu__overlay {
         opacity: 1;
      }

      .mobile-menu__content {
         right: 0;
      }
   }
}

.mobile-menu__overlay {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: rgba(0, 0, 0, 0.75);
   opacity: 0;
   transition: all 0.3s ease-in;
   -webkit-transition: all 0.3s ease-in;
   -moz-transition: all 0.3s ease-in;
}

.mobile-menu__content {
   position: fixed;
   top: 0;
   bottom: 0;
   width: 280px;
   overflow-y: scroll;
   background: #1c1c1c;
   padding: 40px 0px;
   right: -280px;
   transition: all 0.3s ease-in;
   -webkit-transition: all 0.3s ease-in;
   -moz-transition: all 0.3s ease-in;
}

.mobile-menu__close {
   position: absolute;
   top: 15px;
   left: 15px;

   a {
      color: #fff;
      font-size: 20px;
   }
}

.mobile-menu__logo {
   text-align: center;
   color: #fff;
   font-size: 22px;
   line-height: 1;
   padding: 0px 0px 30px 0px;
   margin: 0px;
}

.mobile-menu__nav {
   list-style: none;
   padding: 0px;
   margin: 0px;
   height: 88%;

   > li {
      width: 100%;
      display: block;
      position: relative;
      border-bottom: 1px dashed rgba(255, 255, 255, 0.1);

      > a {
         display: block;
         color: #d5d5d5;
         text-transform: uppercase;
         font-size: 14px;
         font-weight: 400;
         letter-spacing: 0.5px;
         padding: 15px 60px 15px 20px;
      }
   }
}

.mobile-menu__footer {
   margin-left: 25px;

   i {
      font-size: 30px;
      color: #000;
      margin-right: 23px;
      display: inline-block;
      vertical-align: top;
   }

   a {
      display: inline-block;
      vertical-align: top;
      font-size: 12px;
      line-height: 30px;
      color: #999;
      text-transform: uppercase;
      letter-spacing: 1px;

      &:hover {
         color: #000;
      }
   }

   a + a {

      &:before {
         content: '|';
         display: inline-block;
         font-size: 14px;
         color: #999;
         padding: 0px 9px 0px 5px;
      }
   }
}

.mobile-menu__dropdown-btn {
   position: absolute;
   right: 15px;
   top: 10px;
   text-align: center;
   line-height: 35px;
   background: none;
   color: rgba(255, 255, 255, 0.5);
   border: 1px solid rgba(255, 255, 255, 0.5);
   border-radius: 0px;
   padding: 0px;
   width: 36px;
   height: 35px;
}

.mobile-menu__dropdown-menu {
   display: none;
   list-style: none;
   padding: 0px 0px 15px 0px;
   margin: 0px;

   &.active {
      display: block;
   }

   li {
      border: none;
      padding: 0 20px 10px 20px;
      line-height: 1;
      margin: 0px;

      a {
         color: #d5d5d5;
         padding: 0;
         width: 100%;
         display: block;
         font-size: 14px;
         font-weight: 400;
         padding: 0px 20px 0px;
         text-transform: uppercase;
      }

      ul {
         list-style: none;
         padding: 15px 0px 0px 0px;
         margin: 0px;
      }
   }
}

/* Logo */
.header__logo {
   padding: 0px;
   margin: 0px 100px 0px 0px;
   line-height: 1;
   font-size: 30px;
   letter-spacing: 2px;
   font-family: "Pretendard";

   @media (max-width: 1499px) {
      margin-right: 70px;
   }

   @media (max-width: 1399px) {
      margin-right: 50px;
   }

   @media (max-width: 1199px) {
      margin-right: 30px;
   }

   @media (max-width: 1098px) {
      font-size: 26px;
   }

   @media (max-width: 991px) {
      font-size: 22px;
   }

   a {
      color: #000;
   }
}

.logo__image {
   width: 100%;
   max-width: 250px;

   @media (max-width: 991px) {
      max-width: 120px;
   }
}

/* Nav */
.header__nav {
   display: flex;
   align-items: center;
   list-style: none;
   padding: 0px;
   margin: 0px;

   @media (max-width: 991px) {
      display: none;
   }

   > li {
      margin: 0px 32px;

      @media (max-width: 1299px) {
         margin: 0px 25px;
      }  

      @media (max-width: 1098px) {
         margin: 0px 18px;
      }
   }
}

.nav__item {
   color: #000;
   text-transform: uppercase;
   letter-spacing: 0.5px;
   font-size: 16px;
   display: block;
   position: relative;
   z-index: 3;

   &:hover {
      color: #928656;
   }

   &:after {
      left: 50%;
      width: 8px;
      content: "";
      height: 8px;
      bottom: -46px;
      opacity: 0;
      margin: -20px 0px 0px -4px;
      visibility: hidden;
      position: absolute;
      border-radius: 100%;
      background-color: #928656;
      transition: 0.3s all ease-in-out;
   }
}

.header__nav > li {

   &:hover {

      .nav__item:after {
         opacity: 1;
         visibility: visible;
         bottom: -66px;
      }

      .nav__item:before {
         content: '';
         display: block;
         position: absolute;
         top: -60px;
         left: -32px;
         right: -32px;
         bottom: -105px;
      }
   }
}

/* Dropdown menu one level */
.nav__dropdown-menu {
   list-style: none;
   margin: 0px;
   top: 100%;
   margin-top: 0px;
   background: #fff;
   border: 1px solid #d0d0d0;
   border-top-color: #c4c3bd;
   position: absolute;
   width: 280px;
   padding: 30px;
   z-index: 9;
   transform: translateY(40px);
   -webkit-transform: translateY(40px);
   -moz-transform: translateY(40px);
   opacity: 0;
   visibility: hidden;
   pointer-events: none;
   transition: all 0.3s ease-in;
   -webkit-transition: all 0.3s ease-in;
   -moz-transition: all 0.3s ease-in;

   li {

      a {
         color: #000;

         &:hover {
            color: #928656;
         }
      }
   }
}

body.home {

   .nav__dropdown-menu {
      border: none;
      margin-top: 1px;
   }
}

.header__nav {

   li {

      &:hover {

         .nav__dropdown-menu {
            transform: none;
            -webkit-transform: none;
            -moz-transform: none;
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
         }
      }
   }
}

.nav__item-tag {
   display: inline-block;
   vertical-align: top;
   color: #669900;
   font-size: 11px;
   position: relative;
   margin: 1px 0px 0px 5px;
   text-transform: uppercase;
   letter-spacing: 0.5px;
}

.nav__item-tag--default {
   color: #999999;
}

.nav__item-tag--hot {
   color: #ff3333;
}

/* Megamenu */
.nav__mega-menu {
   margin: 0px;
   top: 100%;
   left: 0;
   margin-top: 0px;
   background: #fff;
   border: 1px solid #d0d0d0;
   border-top-color: #c4c3bd;
   position: absolute;
   padding: 65px 70px;
   z-index: 9;
   transform: translateY(40px);
   -webkit-transform: translateY(40px);
   -moz-transform: translateY(40px);
   opacity: 0;
   visibility: hidden;
   pointer-events: none;
   transition: all 0.3s ease-in;
   -webkit-transition: all 0.3s ease-in;
   -moz-transition: all 0.3s ease-in;
   display: flex;
   max-width: 100%;

   @media (max-width: 1098px) {
      padding: 40px;
   }
}

body.home {

   .nav__mega-menu {
      margin-top: 1px;
      border: none;
   }
}

.header__nav {

   li {

      &:hover {

         .nav__mega-menu {
            transform: none;
            -webkit-transform: none;
            -moz-transform: none;
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
         }
      }
   }
}

/* Standard column in megamenu */
.mega-menu__standard-column {
   width: 285px;
   padding-right: 25px;
}

.standard-column__title {
   color: #999999;
   display: block;
   font-size: 14px;
   margin-bottom: 20px;
   letter-spacing: 0.5px;
   text-transform: uppercase;
}

.standard-column__nav {
   list-style: none;
   padding: 0px;
   margin: 0px;

   li {

      a {
         color: #000;

         &:hover {
            color: #928656;
         }
      }
   }
}

.standard-column__nav + .standard-column__title {
   margin-top: 60px;
}

/* Banner in megamenu */
.mega-menu__banner {
   position: relative;
   margin: -65px -70px -65px 0px;
   width: 365px;

   @media (max-width: 1098px) {
      margin: -40px -40px -40px 0px;
   }

   img {
      display: block;
      width: 100%;
   }
}

/* Menu brands */
.menu-brands__items {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   margin-right: -40px;
   position: relative;
}

.menu-brand-item {
   opacity: 0.4;
   transition: all 0.3s ease-in;
   -webkit-transition: all 0.3s ease-in;
   -moz-transition: all 0.3s ease-in;
   margin-right: 35px;
   margin-bottom: 34px;

   &:hover {
      opacity: 1;
   }

   img {
      max-width: 100%;
   }
}

/* Language switcher */
.header__language-switcher {
   display: flex;
   align-items: center;
   list-style: none;
   padding: 0px;
   margin: 0px 0px 0px auto;

   @media (max-width: 991px) {
      display: none;
   }

   > li {
      margin: 0px 10px;

      > a {
         font-size: 14px;
         color: #999;
         text-transform: uppercase;

         &.active,
         &:hover {
            color: #000;
         }
      }
   }
}

/* Header right */
.header__right {
   display: flex;
   align-items: center;
   list-style: none;
   padding: 0px;
   margin: 0px 0px 0px 100px;

   @media (max-width: 1499px) {
      margin-left: 70px;
   }

   @media (max-width: 1399px) {
      margin-left: 50px;
   }

   @media (max-width: 1199px) {
      margin-left: 30px;
   }

   @media (max-width: 1098px) {
      margin-left: 10px;
   }

   @media (max-width: 991px) {
      margin-left: auto;
   }

   > li {
      margin-left: 30px;

      @media (max-width: 991px) {
         margin-left: 20px;
      }

      > a {
         font-size: 23px;
         line-height: 1;
         color: #000;
         display: block;
         position: relative;
         z-index: 9;

         @media (max-width: 991px) {
            font-size: 18px;
         }

         span {
            right: -5px;
            bottom: -5px;
            color: #fff;
            width: 20px;
            height: 20px;
            font-size: 13px;
            line-height: 20px;
            border-radius: 100%;
            position: absolute;
            background-color: #928656;
            text-align: center;

            @media (max-width: 991px) {
               bottom: -7px;
               right: -7px;
            }
         }

         .lnil-user {
            font-size: 25px;

            @media (max-width: 991px) {
               font-size: 20px;
            }
         }

         .lnil-cart {
            font-size: 24px;

            @media (max-width: 991px) {
               font-size: 20px;
            }
         }
      }
   }
}

/* Header cart */
.header__cart {

   &:hover {

      &:before {
         content: '';
         display: block;
         position: absolute;
         top: 0;
         right: 0;
         width: 395px;
         bottom: -1px;
         z-index: 2;
      }

      .header-cart {
         opacity: 1;
         visibility: visible;
         pointer-events: auto;
         transform: none;
         -webkit-transform: none;
         -moz-transform: none;
      }
   }
}

.header-cart {
   position: absolute;
   top: 100%;
   right: 0;
   width: 395px;
   background: #fff;
   margin-top: 0px;
   border: 1px solid #d0d0d0;
   border-top-color: #c4c3bd;
   z-index: 9;
   padding: 40px 30px 47px 30px;
   transform: translateY(40px);
   -webkit-transform: translateY(40px);
   -moz-transform: translateY(40px);
   transition: all 0.3s ease-in;
   -webkit-transition: all 0.3s ease-in;
   -moz-transition: all 0.3s ease-in;
   opacity: 0;
   visibility: hidden;
   pointer-events: none;

   @media (max-width: 991px) {
      display: none !important;
   }

   &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      background: url(loader-2.svg?v=2) top center no-repeat;
      background-size: cover;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;
   }

   > * {
      transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;
   }

   &.is-loading {

      &:before {
         opacity: 1;
      }

      > * {
         opacity: 0;
      }
   }
}

body.home {

   .header-cart {
      margin-top: 1px;
      border: none;
   }
}

.cart__empty {
   padding: 50px 0px;
}

.header-cart__items {
   list-style: none;
   padding: 0px;
   margin: 0px;
}

/* Cart item */
.cart-item {
   margin-top: 26px;

   &:first-of-type {
      margin-top: 0px;
   }
}

.cart-item__image {
   width: 60px;
   min-width: 60px;
   margin: 0px 20px 0px 0px;
   padding: 0px;

   a {
      display: block;
      padding-bottom: 139.948%;
      position: relative;

      img {
         display: block;
         width: 100%;
         height: 100%;
         left: 0;
         right: 0;
         bottom: 0;
         top: 0;
         position: absolute;
         object-fit: cover;
      }
   }
}

.cart-item__image--ratio-100-122 {

   a {
      padding-bottom: 122%;
   }
}

.cart-item__details {
   padding: 0px;
   margin: 0px;
}

.cart-item__title {
   font-size: 16px;
   color: #000;
   display: block;

   @media (max-width: 991px) {
      font-size: 15px;
   }

   &:hover {
      color: #928656;
   }
}

.cart-item__variant {
   display: block;
   font-size: 14px;
   color: #999999;

   @media (max-width: 991px) {
      font-size: 13px;
   }
}

.cart-ietm__price {
   display: block;
   padding: 5px 0px 0px 0px;
   font-size: 16px;
   color: #000;

   @media (max-width: 991px) {
      font-size: 14px;
   }

   i {
      font-style: normal;
      color: #999;
      padding: 0px 4px;
   }
}

.cart-item__delete {
   padding: 0px 0px 0px 20px;
   margin: 0px 0px 0px auto;
   font-size: 12px;

   a {
      font-size: 12px;
      color: #000;
   }
}

/* Cart Subtotal */
.header-cart__subtotal {
   border-top: 1px solid #d0d0d0;
   margin-top: 35px;
   padding-top: 27px;
}

.subtotal__title {
   padding-top: 2px;
}

.subtotal__value {
   padding-left: 20px;
   margin-left: auto;
   font-size: 20px;
   color: #000;
}

/* Cart Action */
.header-cart__action {
   padding-top: 20px;
}

.header-cart__button {
   display: block;
   width: 100%;
   margin: 10px 0px 0px 0px;
   border: 1px solid #999999;
   padding: 12px 25px 12px 25px;
   text-align: center;
   font-size: 14px;
   letter-spacing: 0.75px;
   text-transform: uppercase;
   color: #000;
}

.header-cart__button--checkout {
   background: #928656;
   border-color: #928656;
   color: #fff;

   &:hover {
      color: #fff;
   }
}