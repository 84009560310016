/* ==========================================================================
   18.8 #HOME MINIMAL - TWEET
   ========================================================================== */
.minimal-tweet {
   padding: 0px 0px 160px 0px;

   @media (max-width: 991px) {
      padding: 0px 0px 80px 0px;
   }
}

.minimal-tweet__icon {

   svg {
      margin: 0px auto;
      display: block;

      @media (max-width: 991px) {
         width: 40px;
         height: 40px;
      }

      path {
         fill: #00ccff !important;
      }
   }
}

.minimal-tweet__content {
   max-width: 1060px;
   margin: 55px auto 0px auto;
   text-align: center;
   font-size: 30px;
   line-height: 48px;
   font-family: "Pretendard";
   color: #000;

   @media (max-width: 991px) {
      font-size: 18px;
      line-height: 30px;
      margin-top: 40px;
   }

   a {
      color: #00ccff;
   }
}

.minimal-tweet__meta {
   list-style: none;
   padding: 0px;
   margin: 40px 0px 0px 0px;
   text-align: center;

   li {
      display: inline-block;
      vertical-align: top;
      position: relative;
      font-size: 14px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #666666;

      @media (max-width: 991px) {
         font-size: 11px;
      }

      &:first-child {

         &:before {
            display: none;
         }
      }

      &:before {
         content: '/';
         display: inline-block;
         vertical-align: top;
         margin: 0px 10px 0px 7px;
      }

      a {
         color: #666666;
      }
   }
}