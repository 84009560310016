/* ==========================================================================
   11.8 #FULL WIDTH SLIDER
   ========================================================================== */
.full-width-slider {

   .slick-prev {
      display: block !important;
      top: 50%;
      left: 80px;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      position: absolute;
      opacity: 0.35;
      transition: all 0.3s ease-in-out;
      z-index: 10;
      font-size: 0px;
      background: none;
      border: none;
      outline: none;

      @media (max-width: 1399px) {
         left: 70px;
      }

      @media (max-width: 1098px) {
         left: 50px;
      }

      @media (max-width: 991px) {
         display: none !important;
      }

      &:hover {
         opacity: 1;
      }

      &:before {
         content: "\ecc1";
         display: inline-block;
         font: normal normal normal 1em/1 'LineIconsPro Light';
         speak: none;
         text-transform: none;
         -webkit-font-smoothing: antialiased;
         -moz-osx-font-smoothing: grayscale;
         font-size: 36px;
         color: #000;
      }
   }

   .slick-next {
      display: block !important;
      top: 50%;
      right: 80px;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      position: absolute;
      opacity: 0.35;
      transition: all 0.3s ease-in-out;
      z-index: 10;
      font-size: 0px;
      background: none;
      border: none;
      outline: none;

      @media (max-width: 1399px) {
         right: 70px;
      }

      @media (max-width: 1098px) {
         right: 50px;
      }

      @media (max-width: 991px) {
         display: none !important;
      }

      &:hover {
         opacity: 1;
      }

      &:before {
         content: "\ecc3";
         display: inline-block;
         font: normal normal normal 1em/1 'LineIconsPro Light';
         speak: none;
         text-transform: none;
         -webkit-font-smoothing: antialiased;
         -moz-osx-font-smoothing: grayscale;
         font-size: 36px;
         color: #000;
      }
   }

   .slick-dots {
      display: block !important;
      position: absolute;
      list-style: none;
      padding: 0px;
      margin: 0px;
      right: 80px;
      bottom: 90px;
      z-index: 10;

      @media (max-width: 1399px) {
         right: 70px;
      }

      @media (max-width: 1098px) {
         right: 50px;
      }

      @media (max-width: 991px) {
         right: 40px;
         width: 95%;
         max-width: 335px;
         bottom: 50px;
      }

      li {
         display: inline-block;
         vertical-align: top;

         &.slick-active {

            button {
               border-color: #fff;
            }
         }

         button {
            width: 25px;
            height: 25px;
            margin-left: 10px;
            position: relative;
            border-radius: 100%;
            display: block;
            border: 1px solid #fff;
            border-color: transparent;
            background: none;
            font-size: 0px;
            outline: none;

            &:before {
               top: 50%;
               left: 50%;
               width: 6px;
               height: 6px;
               content: "";
               position: absolute;
               border-radius: 100%;
               background-color: #fff;
               transform: translate(-50%, -50%);
               -webkit-transform: translate(-50%, -50%);
               -moz-transform: translate(-50%, -50%);
            }
         }
      }
   }
}

.full-width-slider__item {
   height: auto;
   position: relative;
   display: none !important;
   background: #795d4b;

   &:first-of-type {
      display: block !important;
   }
}

/* Image */
.full-width-slider__image {
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;

   img {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;

      @media (min-width: 992px) {
         object-fit: cover;
         height: 100%;
      }

      @media (max-width: 991px) {
         object-position: 40%;
      }

      &.active {
         display: block;
      }
   }
}

/* Current slider */
.full-width-slider__current-slider {
   position: absolute;
   bottom: 75px;
   left: 75px;
   z-index: 9;
   text-align: center;

   @media (max-width: 1399px) {
      left: 65px;
   }

   @media (max-width: 1098px) {
      left: 45px;
   }

   @media (max-width: 991px) {
      display: none;
   }

   span {
      display: block;
      color: #a4846e;
      font-size: 36px;
      font-weight: 300;
      padding-right: 3px;

      &.current-slider {
         color: #fff;
      }
   }

   svg {

      path {
         fill: #fff !important;
         opacity: 0.25;
      }
   }
}

/* Feature */
.full-width-slider__feature {
   position: absolute;
   top: 200px;
   left: 76px;
   color: rgba(255, 255, 255, 0.5);
   font-size: 18px;
   line-height: 30px;
   text-transform: uppercase;
   letter-spacing: 1px;
   z-index: 9;
   transform: rotate(-90deg);
   -webkit-transform: rotate(-90deg);
   -moz-transform: rotate(-90deg);
   text-align: right;
   width: 120px;
   height: 120px;
   white-space: nowrap;
   letter-spacing: 1px;

   @media (max-width: 1399px) {
      left: 66px;
   }

   @media (max-width: 1098px) {
      left: 46px;
   }

   @media (max-width: 991px) {
      display: none;
   }
}

/* Content */
.full-width-slider__content {
   position: absolute;
   left: 52%;
   width: 45%;
   max-width: 600px;
   color: #fff;
   top: 50%;
   transform: translateY(-50%);
   -webkit-transform: translateY(-50%);
   -moz-transform: translateY(-50%);
   z-index: 9;

   @media (max-width: 1199px) {
      left: 45%;
   }

   @media (max-width: 991px) {
      right: 40px;
      left: auto;
      width: 95%;
      max-width: 325px;
      top: 140px;
      transform: none;
      -webkit-transform: none;
      -moz-transform: none;
   }
}

.full-width-slider__content--with-image-swatches {
   padding-top: 50px;
}

/* Subtitle */
.full-width-slider__subtitle {
   font-size: 24px;
   line-height: 1;
   letter-spacing: 1px;
   padding-bottom: 65px;
   display: block;
   text-transform: uppercase;

   @media (max-width: 1098px) {
      font-size: 21px;
   }

   @media (max-width: 991px) {
      font-size: 14px;
      padding-bottom: 40px;
   }
}

/* Title */
.full-width-slider__title {
   color: #fff;
   line-height: 1;
   letter-spacing: 1px;
   padding: 0px 0px 25px 0px;
   margin: 0px;
   font-size: 90px;

   @media (max-width: 1199px) {
      font-size: 72px;
   }

   @media (max-width: 1098px) {
      font-size: 60px;
   }

   @media (max-width: 991px) {
      font-size: 48px;
      line-height: 54px;
      padding-bottom: 20px;
   }
}

/* Descripion */
.full-width-slider__description {
   font-size: 24px;
   color: rgba(255, 255, 255, 0.5);
   padding: 0px 0px 35px 0px;
   margin: 0px;

   @media (max-width: 1098px) {
      font-size: 21px;
   }

   @media (max-width: 991px) {
      font-size: 14px;
      padding-bottom: 25px;
   }
}

/* Start from */
.full-width-slider__start-from {
   padding: 20px 0px 30px 0px;

   @media (max-width: 991px) {
      padding: 10px 0px 20px 0px;
   }

   span {
      text-transform: uppercase;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 1px;
      padding-bottom: 10px;
      display: block;

      @media (max-width: 991px) {
         font-size: 11px;
      }
   }

   p {
      color: #daf94c;
      font-size: 48px;
      line-height: 1;
      padding: 0px;
      margin: 0px;

      @media (max-width: 991px) {
         font-size: 30px;
      }
   }
}

/* Price */
.full-width-slider__price {
   color: #ffbb39;
   font-size: 48px;

   @media (max-width: 991px) {
      font-size: 30px;
      line-height: 1.667;
   }

   del {
      color: #fff;
      font-size: 24px;
      padding-left: 5px;
      display: inline-block;
      vertical-align: top;
      padding-top: 24px;

      @media (max-width: 991px) {
         font-size: 16px;
         padding-top: 15px;
      }
   }
}

/* Image swatches */
.full-width-slider__image-swatches {
   padding: 50px 0px 40px 0px;

   @media (max-width: 991px) {
      padding: 30px 0px 20px 0px;
   }

   span {
      font-weight: 700;
      display: block;
      margin-bottom: 20px;
      font-size: 14px;
      text-transform: uppercase;

      @media (max-width: 991px) {
         font-size: 11px;
         margin-bottom: 16px;
      }
   }

   ul {
      list-style: none;
      padding: 0px;
      margin: -8px 0px;
      display: flex;
      position: relative;

      li {
         padding: 8px 16px 8px 0px;

         a {
            display: block;

            &.active {

               img {
                  border: 1px solid #fff;
               }
            }

            img {
               width: 70px;
               height: 85px;
               object-fit: cover;

               @media (max-width: 991px) {
                  width: 50px;
                  height: 60px;
               }
            }
         }
      }
   }
}

/* Buy now button */
.full-width-slider__buy-now {
   padding-top: 50px;

   @media (max-width: 991px) {
      padding-top: 40px;
   }

   a {
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
      display: inline-block;
      position: relative;
      color: #fff;

      @media (max-width: 991px) {
         font-size: 16px;
      }

      &:after {
         left: 0;
         bottom: 0;
         width: 100%;
         height: 1px;
         content: "";
         position: absolute;
         background-color: #fff;
      }
   }
}