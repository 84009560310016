/* ==========================================================================
   7.1 #FEATURED COLLECTIONS
   ========================================================================== */
.featured-collections {
   padding-top: 140px;
   padding-bottom: 175px;

   @media (max-width: 991px) {
      padding-top: 65px;
      padding-bottom: 75px;
   }

   .row {
      margin: 0px -45px;

      @media (max-width: 1399px) {
         margin: 0px -25px;
      }

      > div {
         padding: 0px 45px;

         @media (max-width: 1399px) {
            padding: 0px 25px;
         }
      }
   }
}

/* Title */
.featured-collections__title {
   padding: 0px 0px 30px 0px;
   margin: 0px;
}

/* Description */
.featured-collections__description {

   @media (max-width: 991px) {
      padding-bottom: 25px;
   }
}

/* Featured product */
.featured-product {
   background: #fff;
   height: 660px;
   margin-top: 75px;
   overflow: hidden;
   position: relative;

   @media (max-width: 1098px) {
      height: 560px;
   }

   @media (max-width: 991px) {
      height: 330px;
      margin-top: 20px;
   }

   &:hover {

      .featured-product__image {

         img {
            transform: scale(1.2);
         }
      }
   }
}

.featured-product__image {
   position: absolute;
   top: -30px;
   right: -155px;

   img {
      transition: 0.75s all ease-in-out;

      @media (max-width: 991px) {
         max-width: 100%;
      }
   }
}

.featured-product__image--style-2 {
   top: 95px;
   right: -240px;
}

.featured-product__content {
   position: absolute;
   z-index: 2;
   top: 80px;
   left: 80px;
   bottom: 80px;
   right: 80px;
   max-width: 235px;
   padding-bottom: 90px;

   @media (max-width: 1399px) {
      top: 60px;
      left: 60px;
      bottom: 60px;
      right: 60px;
   }

   @media (max-width: 1098px) {
      max-width: 50%;
   }

   @media (max-width: 991px) {
      top: 35px;
      left: 35px;
      right: 35px;
      bottom: 35px;
   }
}

.featured-product__category {
   letter-spacing: 0.5px;
   color: #928656;
   text-transform: uppercase;
   padding: 0px 0px 10px 0px;
   margin: 0px;

   @media (max-width: 991px) {
      font-size: 10px;
   }
}

.featured-product__product-name {
   padding: 0px 0px 40px 0px;
   margin: 0px;
   text-transform: uppercase;
}

.featured-product__product-description {

   @media (max-width: 991px) {
      display: none;
   }
}

.featured-product__button {
   position: absolute;
   bottom: 0px;
}