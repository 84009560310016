/* ==========================================================================
   16.1 #HOME BEST SELLING - HEADER
   ========================================================================== */
.header--type-4-black {

   .mobile-menu__open a {
      color: #000;
   }

   .header__logo a {
      color: #000;
   }

   .header__desktop-menu a {
      color: #000;
   }

   .header__right > li > a {
      color: #000;
   }

   .header__right > li > a span {
      background: #5e4d30;
   }
}