/* ==========================================================================
   8.10 #NOT FOUND PAGE
   ========================================================================== */
.not-found-page {
   text-align: center;
   padding: 150px 0px;

   @media (max-width: 991px) {
      padding: 85px 0px;
   }
}

.not-found-page__title {
   margin: 0px;
   line-height: 1;
   font-size: 250px;
   color: #928656;
   padding: 0px 0px 30px 0px;

   @media (max-width: 1098px) {
      font-size: 180px;
   }

   @media (max-width: 991px) {
      font-size: 60px;
   }
}

.not-found-page__description {
   max-width: 570px;
   margin: 0px auto;
   font-size: 36px;
   color: #000;
   padding: 0px 0px 70px 0px;

   @media (max-width: 1098px) {
      font-size: 30px;
   }

   @media (max-width: 991px) {
      font-size: 18px;
      padding-bottom: 40px;
   }
}