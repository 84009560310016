/* ==========================================================================
   #PRODUCT LAYOUT 3
   ========================================================================== */
.product-layout-3 {

   .product__main {

      @media (max-width: 1199px) {
         flex-wrap: wrap;
      }
   }

   .product__main-image-and-thumbnails {
      width: 38.15%;
      min-width: 38.15%;
      position: relative;

      @media (max-width: 1199px) {
         width: 50%;
         min-width: 50%;
      }
   }  

   .product__right {
      width: 30%;
      min-width: 30%;
      margin-left: 6.4%;

      @media (max-width: 1399px) {
         margin-left: 4.4%;
      }

      @media (max-width: 1199px) {
         width: 45.6%;
         min-width: 45.6%;
      }

      @media (max-width: 991px) {
         min-width: 100%;
         min-width: 100%;
         margin-left: 0px;
      }
   }

   .product__main-image-and-thumbnails .product__thumbnails li {
      margin: 0px 10px 0px 0px !important;
      width: 14%;
      min-width: 14%;
   }

   .product__thumbnails li a {
      opacity: 1;
   }
}

.product__sidebar {
   margin-left: 6.4%;
   width: 19.05%;
   min-width: 19.05%;

   @media (max-width: 1399px) {
      margin-left: 4.4%;
      width: 23.05%;
      min-width: 23.05%;
   }

   @media (max-width: 1199px) {
      width: 100%;
      min-width: 100%;
      margin: 60px 0px 0px 0px;
   }

   @media (max-width: 991px) {
      margin: 15px 0px 0px 0px;
   }
}

.product-sidebar__features {
   border: 1px solid #d0d0d0;
   padding: 0px 33px 40px 33px;

   @media (max-width: 991px) {
      border: none;
      padding: 0px;
   }

   .home-about-us__feature {
      margin: 40px 0px 0px 0px;
      padding: 0px;

      @media (max-width: 991px) {
         margin: 25px 0px 0px 0px;;
      }
   }

   .feature__icon {
      width: 38px;
      margin-right: 30px;

      @media (max-width: 991px) {
         width: 30px;
         margin-right: 25px;
      }

      i {
         font-size: 38px;
         color: #928656;

         @media (max-width: 991px) {
            font-size: 30px;
         }
      }
   }

   .feature__h6 {
      font-size: 16px;
      font-weight: 400;
      padding: 0px 0px 10px 0px;
      margin: 0px;

      @media (max-width: 991px) {
         font-size: 14px;
      }
   }   

   .feature__description {
      font-size: 14px;
      line-height: 24px;

      @media (max-width: 991px) {
         font-size: 12px;
         line-height: 21px;
      }
   }
}

.product__safe-checkout {
   padding: 47px 0px 0px 0px;
   text-align: center;

   @media (max-width: 991px) {
      padding: 42px 0px 0px 0px;
   }

   img {
      max-width: 100%;
   }
}