/* ==========================================================================
   15.10 #HOME CLASSIC - OUR PRODUCTS
   ========================================================================== */
.classic-our-products {
   padding: 90px 0px 0px 0px;

   @media (max-width: 991px) {
      padding-top: 65px;
   }

   .slick-prev {
      left: -120px;
   }

   .slick-next {  
      right: -120px;
   }

   .our-products__title {
      text-align: center;
      padding: 0px 0px 55px 0px;
      font-size: 36px;

      @media (max-width: 991px) {
         padding-bottom: 35px;
         font-size: 24px;
      }
   }

   .our-products__nav {
      padding-bottom: 45px;

      @media (max-width: 991px) {
         padding: 0px;
         margin: 0px 0px 40px 0px;;
      }

      li {
         margin: 0px 35px;

         @media (max-width: 991px) {
            margin: 0px 18px;
         }

         &:first-child {
            margin-left: auto;
         }

         &:last-child {
            margin-right: auto;
         }

         a {
            font-size: 18px;
            letter-spacing: 1px;
            color: #000;

            @media (max-width: 991px) {
               font-size: 13px;
               letter-spacing: 0.5px;
            }

            &:hover {
               color: #b5af95;
            }

            &.active {
               font-weight: 400;
               color: #b5af95;
            }
         }
      }
   }

   .slick-dots {

      li.slick-active {

         button {
            background: #d8d2ba;
         }
      }
   }
}