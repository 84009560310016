/* ==========================================================================
   8.11 #ORDER COMPLETE PAGE
   ========================================================================== */
.order-complete {
   padding: 0px 0px 155px 0px;

   @media (max-width: 991px) {
      padding: 0px 0px 85px 0px;
   }

   .container {

      .container {

         @media (max-width: 991px) {
            padding: 0px;
         }
      }
   }
}

/* Container */
.order-complete__container {
   max-width: 770px;
   margin: 0px auto;
}

/* Heading */
.order-complete__heading {
   margin: 90px 0px 0px 0px;
   display: flex;
   align-items: center;

   @media (max-width: 991px) {
      margin-top: 50px;
   }
}

.order-complete__h1 {
   font-size: 48px;
   padding: 0px;
   margin: 0px;

   @media (max-width: 1098px) {
      font-size: 40px;
   }

   @media (max-width: 991px) {
      font-size: 26px;
      width: 100%;
   }
}

.order-complete__order-number {
   margin-left: auto;
   padding-left: 20px;
   font-size: 14px;
   color: #999999;
   text-transform: uppercase;
   letter-spacing: 1px;
   text-align: right;
   line-height: 1.4;
   white-space: nowrap;

   @media (max-width: 991px) {
      font-size: 13px;
   }

   p {
      padding: 0px;
      margin: 0px;
      font-size: 18px;
      color: #000;

      @media (max-width: 991px) {
         font-size: 14px;
      }
   }
}

/* Description */
.order-complete__description {
   padding: 60px 0px 0px 0px;
   font-size: 16px;

   @media (max-width: 991px) {
      font-size: 14px;
      padding-top: 35px;
   }
}

/* Title order summary */
.order-complete__order-summary {
   border-top: 1px solid #dedede;
   margin: 20px 0px 0px 0px;
   padding: 40px 0px 20px 0px;
   font-size: 24px;
   font-family: 'Jost';
   letter-spacing: 0.5px;

   @media (max-width: 1098px) {
      font-size: 21px;
   }

   @media (max-width: 991px) {
      font-size: 18px;
   }
}

/* Products */
.order-complete__products {
   padding: 0px 0px 30px 0px;
   margin: 0px;
   border-bottom: 1px solid #dedede;
}

.order-complete__product {
   display: flex;
   padding: 20px 0px 0px 0px;
}

.order-complete-product__image {
   width: 70px;
   min-width: 70px;

   a {
      display: block;
      position: relative;
      padding-bottom: 139.948%;

      img {
         display: block;
         width: 100%;
         height: 100%;
         left: 0;
         right: 0;
         bottom: 0;
         top: 0;
         position: absolute;
         object-fit: cover;
      }
   }
}

.order-complete-product__title-and-variant {
   padding-left: 15px;
}

.order-complete-product__title {
   font-size: 18px;
   line-height: 1.3;
   margin-bottom: 0;
   letter-spacing: 0px;
   color: #000;
   font-family: "Pretendard";
   font-weight: 400;

   @media (max-width: 991px) {
      font-size: 14px;
   }

   a {
      color: #000;
      letter-spacing: 0.5px;

      &:hover {
         color: #928656;
      }
   }
}

.order-complete-product__variant {
   font-size: 14px;
   color: #999999;
   padding: 6px 0px 0px 0px;

   @media (max-width: 991px) {
      font-size: 12px;
   }
}

.order-complete-product__price {
   margin-left: auto;
   padding-left: 20px;
   text-align: right;
   font-size: 18px;
   color: #000;
   line-height: 1.3;

   @media (max-width: 991px) {
      font-size: 14px;
   }
}

/* Subtotal and shipping */
.order-complete__subtotal-and-shipping {
   max-width: 370px;
   margin: 30px 0px 0px auto;
   padding: 0px;

   @media (max-width: 767px) {
      max-width: 100%;
   }
}

.order-complete__subtotal {
   display: flex;
   padding-top: 4px;
}

.order-complete-subtotal__title {
   font-size: 16px;

   @media (max-width: 991px) {
      font-size: 14px;
   }
}

.order-complete-subtotal__price {
   padding-left: 20px;
   margin-left: auto;
   text-align: right;
   font-size: 16px;
   color: #000;

   @media (max-width: 991px) {
      font-size: 14px;
   }
}

.order-complete__shipping {
   display: flex;
   padding-top: 4px;
}

.order-complete-shipping__title {
   font-size: 16px;

   @media (max-width: 991px) {
      font-size: 14px;
   }
}

.order-complete-shipping__price {
   padding-left: 20px;
   margin-left: auto;
   text-align: right;
   color: #ea311d;
   font-size: 16px;

   @media (max-width: 991px) {
      font-size: 14px;
   }
}

/* Total */
.order-complete__total {
   display: flex;
   align-items: center;
   padding: 30px 0px 0px 0px;
   margin: 20px 0px 0px auto;
   border-top: 1px solid #d0d0d0;
   max-width: 370px;

   @media (max-width: 767px) {
      max-width: 100%;
   }
}

.order-complete-total__title {
   text-transform: uppercase;
   letter-spacing: 0.5px;
   color: #000;
}

.order-complete-total__price {
   margin-left: auto;
   padding-left: 20px;
   text-align: right;
   color: #000;
   font-weight: 600;
   font-size: 20px;

   @media (max-width: 991px) {
      font-size: 18px;
   }
}

/* Customer information */
.order-complete__customer-information {
   margin-top: 45px;
   border-top: 1px solid #dedede;
   padding-top: 45px;
}

.customer-information__heading {
   margin: 0px 0px 0px 0px;
   padding: 0px 0px 5px 0px;
   font-size: 24px;
   font-family: 'Jost';
   letter-spacing: 0.5px;

   @media (max-width: 1098px) {
      font-size: 21px;
   }

   @media (max-width: 991px) {
      font-size: 18px;
   }
}

.customer-information__section {
   padding: 30px 0px 0px 0px;
}

.order-information__section-heading {
   font-family: 'Jost';
   color: #999;
   font-size: 13px;
   letter-spacing: 0.5px;
   text-transform: uppercase;
   padding: 0px 0px 15px 0px;
   margin: 0px;

   @media (max-width: 991px) {
      font-size: 12px;
   }
}

.order-information__section-content {
   font-size: 16px;
   line-height: 30px;
   color: #000;

   @media (max-width: 991px) {
      font-size: 14px;
      line-height: 24px;
   }

   span {
      color: #ea311d;
   }
}

/* Action */
.order-complete__action {
   border-top: 1px solid #dedede;
   margin-top: 50px;
   padding-top: 40px;

   @media (max-width: 991px) {
      padding-top: 25px;
   }

   .button,
   .second-button,
   .third-button,
   .fourth-button,
   .fifth-button,
   .sixth-button {
      display: block;
      width: 100%;
      text-align: center;
      margin-top: 30px;

      @media (max-width: 767px) {
         margin-top: 20px;
      }
   }

   .third-button {
      padding-top: 12px;
      padding-bottom: 12px;

      @media (max-width: 991px) {
         padding-top: 8px;
         padding-bottom: 8px;
      }
   }
}