/* ==========================================================================
   #HEADING
   ========================================================================== */
h1, 
h2, 
h3, 
h4, 
h5, 
h6 {
  font-size: 30px;
  line-height: 1.11;
  font-weight: 400;
  font-family: "Pretendard";
  letter-spacing: 2px;
  color: #000;
  padding: 0px;
  margin: 0px 0px 20px 0px;

  @media (max-width: 991px) {
    letter-spacing: 1px;
  }
}

h1 {
  font-size: 30px;

  @media (max-width: 1098px) {
    font-size: 26px;
  }

  @media (max-width: 991px) {
    font-size: 22px;
  }
}

h2 {
  font-size: 90px;

  @media (max-width: 1098px) {
    font-size: 60px;
  }

  @media (max-width: 991px) {
    font-size: 36px;
  }
}

h3 {
  font-size: 60px;
  letter-spacing: 1px;

  @media (max-width: 1098px) {
    font-size: 48px;
  }

  @media (max-width: 991px) {
    font-size: 36px;
  }
}

h4 {
  font-size: 48px;
  letter-spacing: 1px;

  @media (max-width: 1098px) {
    font-size: 40px;
  }

  @media (max-width: 991px) {
    font-size: 26px;
  }
}

h5 {
  font-size: 24px;
  letter-spacing: 1px;

  @media (max-width: 991px) {
    font-size: 18px;
  }
}

h6 {
  font-size: 24px;
  font-family: "Pretendard";
  letter-spacing: 1px;
  text-transform: uppercase;

  @media (max-width: 1098px) {
    font-size: 21px;
  }

  @media (max-width: 991px) {
    font-size: 18px;
  }
}

.font-family-jost {
  font-family: "Pretendard";
  font-weight: 300;
  line-height: 1.333;
  letter-spacing: 0px;

  a {
    letter-spacing: 0px !important;
  }
}

.font-weight-400 {
  font-weight: 400;
}

.text-transform-none {
  text-transform: none !important;
}