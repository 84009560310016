/* ==========================================================================
   14.7 #HOME DARK - OUR PRODUCTS
   ========================================================================== */
.dark-our-products {
   padding-bottom: 0px;

   .our-products__nav li a {
      font-size: 18px;

      @media (max-width: 991px) {
         font-size: 14px;
      }
   }

   .our-products__nav li a i {
      margin-top: 2px;

      @media (max-width: 991px) {
         font-size: 18px;
      }
   }

   .eighth-button {
      margin-left: 4px;
      margin-right: 4px;
      font-size: 18px;
      font-weight: 600;
      color: #000;
      border-color: #000;

      @media (max-width: 1199px) {
         font-size: 16px;
      }

      @media (max-width: 991px) {
         font-size: 13px;
         display: block;
         margin: 0px 0px 10px 0px;

         &:last-of-type {
            margin: 0px;
         }
      }

      &:hover {
         border-color: #da5f39;
         background: #da5f39;
         color: #fff;
      }
   }

   .row {

      @media (max-width: 991px) {
         margin: 0px -5px;
      }

      > div {

         @media (max-width: 991px) {
            padding: 0px 5px;
         }
      }
   }

   .product-grid-item {

      @media (max-width: 991px) {
         margin-bottom: 25px;
      }
   }

   .col-6 {

      @media (max-width: 991px) {

         .product-grid-item__colors {
            margin-top: 10px;

            .product-grid-item__color {
               width: 8px;
               height: 8px;
            }
         }

         .product-grid-item__image-colors {
            padding-top: 15px;

            li {
               width: 28px;
               margin: 5px 5px 0px 0px;
            }
         }

         .product-grid-item__sizes {
            padding-top: 10px;
         }

         .product-grid-item__size {
            min-width: 26px;
            min-height: 26px;
            font-size: 9px;
            line-height: 1.667;
            padding: 5px 5px;
         }

         .product-grid-item__tag {
            left: 11px;
            top: 14px;
            font-size: 9px;
            padding: 4px 9px;
         }

         .product-grid-item__feature {
            font-size: 10px;
            margin-bottom: -15px;
            padding: 15px 0px 0px 0px;
         }

         .product-grid-item__name {
            margin-top: 15px;
            font-size: 14px;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
         }

         .product-grid-item__price {
            padding-top: 3px;
         }

         .product-grid-item__rating,
         .product-grid-item__wishlist-right {
            display: none;
         }
      }
   }
}