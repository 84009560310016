/* ==========================================================================
   12.2 #HOME MODERN DEAL WEEK
   ========================================================================== */
.modern-deal-week {
   background: #c8c1b8;
   color: #fff;
   padding: 140px 0px 65px;
   text-align: center;

   @media (max-width: 991px) {
      padding: 80px 0px 70px 0px;
      background-size: cover;
      background-position: 48% center;
   }
}

.modern-deal-week__tag {
   text-transform: uppercase;
   letter-spacing: 3px;
   margin: 0px;
   padding: 0px 0px 40px 0px;

   @media (max-width: 991px) {
      padding: 0px 0px 25px 0px;
      font-size: 12px;
      letter-spacing: 2px;
   }
}

.modern-deal-week__title {
   color: #fff;
   font-size: 72px;
   margin: 0 auto;
   padding: 0px 0px 25px 0px;
   font-weight: 300;
   line-height: 1.111;
   max-width: 690px;

   @media (max-width: 1098px) {
      font-size: 60px;
   }

   @media (max-width: 991px) {
      font-size: 42px;
      padding-bottom: 10px;
   }
}

.modern-deal-week__countdown {

   ul {
      list-style: none;
      padding: 0px;
      margin: 0px;

      li {
         display: inline-block;
         vertical-align: top;
         margin: 30px 35px 0px 35px;
         color: #fff;
         text-align: center;

         @media (max-width: 991px) {
            margin-left: 20px;
            margin-right: 20px;
         }

         .counter__value {
            display: block;    
            line-height: 1;
            font-size: 72px;
            font-weight: 300;
            margin-bottom: 15px;

            @media (max-width: 1098px) {
               font-size: 60px;
            }

            @media (max-width: 991px) {
               font-size: 42px;
            }
         }

         .counter__title {
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 1px;

            @media (max-width: 991px) {
               font-size: 10px;
               letter-spacing: 0.5px;
            }
         }
      }
   }
}

.modern-deal-week__button {
   padding: 70px 0px 0px 0px;
   margin: 0px;

   @media (max-width: 991px) {
      padding-top: 50px;
   }

   a {
      display: inline-block;
      letter-spacing: 1px;
      color: #000;
      text-transform: uppercase;
      background: #fff;
      padding: 15px 35px 15px 35px;
      transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;

      @media (max-width: 991px) {
         padding: 9px 27px 9px 27px;
      }

      &:hover {
         background: #000;
         color: #fff;
      }
   }
}

.modern-deal-week__description {
   padding: 120px 0px 0px 0px;
   font-size: 16px;

   @media (max-width: 991px) {
      padding-top: 60px;
      font-size: 13px;
   }

   span {
      color: #000;
      padding: 3px 5px;
      background-color: #fee2a7;
   }
}