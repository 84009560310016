/* ==========================================================================
   7.5 #INSTAGRAM
   ========================================================================== */
.instagram {
   background: #fff;
   padding: 0px 0px 210px 0px;

   @media (max-width: 991px) {
      padding-bottom: 90px;
   }

   .container {

      .container {

         @media (max-width: 991px) {
            padding: 0px;
         }
      }
   }
}

.instagram__title {
   padding: 125px 0px 30px 0px;
   margin: 0px;
   text-transform: uppercase;

   @media (max-width: 991px) {
      padding: 67px 0px 20px 0px;
      font-size: 16px;
   }
}

.instagram__description {

   @media (max-width: 991px) {
      font-size: 13px;
   }

   a {
      color: #000;

      &:hover {
         color: #928656;
      }
   }
}

/* Instagram feed */
.instagram_feed {
   display: flex;
   list-style: none;
   padding: 75px 0px 0px 0px;
   margin: 0px -5px;
   position: relative;
   overflow: hidden;

   @media (max-width: 991px) {
      margin: 0px -3px;
      padding-top: 40px;
   }

   li {
      width: 20%;
      min-width: 20%;
      padding: 0px 5px;

      @media (max-width: 991px) {
         width: 25%;
         min-width: 25%;
         padding: 0px 3px;
      }

      @media (max-width: 767px) {
         width: 33.333%;
         min-width: 33.333%;
      }

      a {
         display: block;
         padding-bottom: 100%;
         position: relative;

         img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
         }

         i {
            left: 0;
            right: 0;
            top: 50%;
            z-index: 3;
            color: #fff;
            opacity: 0;
            font-size: 48px;
            text-align: center;
            visibility: hidden;
            position: absolute;
            transform: translateY(-50%);
            margin-top: -30px;
            transition: 0.3s all ease-in-out;
         }

         &:after {
            top: 0;
            left: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            content: "";
            position: absolute;
            visibility: hidden;
            background-color: #000;
            transition: 0.3s all ease-in-out;
            z-index: 2;
         }

         &:hover {

            i {
               opacity: 1;
               margin-top: 0;
               visibility: visible;
            }

            &:after {
               opacity: 0.7;
               visibility: visible;
            }
         }
      }
   }
}

