/* ==========================================================================
   11.7 #FULL WIDTH PRODUCT TABS
   ========================================================================== */
.product-tabs {
   padding: 100px 0px 120px 0px;

   @media (max-width: 991px) {
      padding: 60px 0px 65px 0px;
   }

   .products-row .product-grid-item {
      margin-top: 50px;
      margin-bottom: 0px;

      @media (max-width: 991px) {
         margin-top: 35px;
      }
   }


   .col-12 {

      .product-grid-item {

         @media (max-width: 991px) {
            font-size: 18px;
         }
      }
   }

   .col-6 {

      @media (max-width: 991px) {

         .product-grid-item__colors {
            margin-top: 10px;

            .product-grid-item__color {
               width: 8px;
               height: 8px;
            }
         }

         .product-grid-item__image-colors {
            padding-top: 15px;

            li {
               width: 28px;
               margin: 5px 5px 0px 0px;
            }
         }

         .product-grid-item__sizes {
            padding-top: 10px;
         }

         .product-grid-item__size {
            min-width: 26px;
            min-height: 26px;
            font-size: 9px;
            line-height: 1.667;
            padding: 5px 5px;
         }

         .product-grid-item__tag {
            left: 11px;
            top: 14px;
            font-size: 9px;
            padding: 4px 9px;
         }

         .product-grid-item__feature {
            font-size: 10px;
            padding: 15px 0px 0px 0px;
         }

         .product-grid-item__name {
            margin-top: 15px;
            font-size: 14px;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
         }

         .product-grid-item__price {
            padding-top: 3px;
         }
      }
   }
}

.product-tabs__tabs-and-action {
   padding-bottom: 10px;

   @media (max-width: 991px) {
      padding-bottom: 0px;
   }
}

.product-tabs__tabs {
   display: flex;
   flex-wrap: wrap;
   list-style: none;
   padding: 0px;
   margin: 0px;

   @media (max-width: 991px) {
      flex-wrap: nowrap;
      overflow-x: scroll;
      white-space: nowrap;
   }

   li {
      margin-right: 75px;

      @media (max-width: 991px) {
         margin-right: 50px;

         &:last-child {
            margin-right: 0px;
         }
      }

      span {
         font-family: "Pretendard";
         font-size: 36px;
         color: #000;

         @media (max-width: 1199px) {
            font-size: 30px;
         }

         @media (max-width: 991px) {
            font-size: 24px;
         }
      }
   }
}

.product-tabs__action {
   margin-left: auto;
   text-align: right;

   @media (max-width: 991px) {
      display: none;
   }

   a {
      color: #000;
      letter-spacing: 0.75px;
      text-transform: uppercase;

      i {
         padding-left: 7px;
      }
   }
}