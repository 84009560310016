/* ==========================================================================
   15.7 #HOME CLASSIC - LIMITED EDITION
   ========================================================================== */
.classic-limited-edition {
   padding: 120px 0px 100px 0px;

   @media (max-width: 991px) {
      padding: 70px 0px 60px 0px;
   }

   .slick-prev {
      left: -120px;
   }

   .slick-next {  
      right: -120px;
   }

   .slick-dots {

      @media (min-width: 992px) {
         display: none !important;
      }
   }

   .our-products__title {
      text-align: center;
      padding: 0px 0px 65px 0px;
      margin: 0px;
      font-size: 36px;

      @media (max-width: 991px) {
         font-size: 24px;
         padding: 0px 0px 45px 0px;
      }
   }
}

.classic-limited-edition--type-2 {
   padding-bottom: 175px !important;
   padding-top: 0px !important;

   @media (max-width: 991px) {
      padding-bottom: 90px !important;
   }
}