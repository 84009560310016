/* ==========================================================================
   13.7 #HOME COLLECTION - SLIDER
   ========================================================================== */
.collection-slider {

   .slick-prev {
      display: block !important;
      top: 50%;
      left: 80px;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      position: absolute;
      opacity: 0.35;
      transition: all 0.3s ease-in-out;
      z-index: 10;
      font-size: 0px;
      background: none;
      border: none;
      outline: none;

      @media (max-width: 1399px) {
         left: 70px;
      }

      @media (max-width: 1098px) {
         left: 50px;
      }

      @media (max-width: 991px) {
         display: none !important;
      }

      &:hover {
         opacity: 1;
      }

      &:before {
         content: "\ecc1";
         display: inline-block;
         font: normal normal normal 1em/1 'LineIconsPro Light';
         speak: none;
         text-transform: none;
         -webkit-font-smoothing: antialiased;
         -moz-osx-font-smoothing: grayscale;
         font-size: 36px;
         color: #fff;
      }
   }

   .slick-next {
      display: block !important;
      top: 50%;
      right: 80px;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      position: absolute;
      opacity: 0.35;
      transition: all 0.3s ease-in-out;
      z-index: 10;
      font-size: 0px;
      background: none;
      border: none;
      outline: none;

      @media (max-width: 1399px) {
         right: 70px;
      }

      @media (max-width: 1098px) {
         right: 50px;
      }

      @media (max-width: 991px) {
         display: none !important;
      }

      &:hover {
         opacity: 1;
      }

      &:before {
         content: "\ecc3";
         display: inline-block;
         font: normal normal normal 1em/1 'LineIconsPro Light';
         speak: none;
         text-transform: none;
         -webkit-font-smoothing: antialiased;
         -moz-osx-font-smoothing: grayscale;
         font-size: 36px;
         color: #fff;
      }
   }

   .slick-dots {
      display: block !important;
      position: absolute;
      list-style: none;
      padding: 0px;
      margin: 0px;
      right: 80px;
      bottom: 90px;
      z-index: 10;

      @media (max-width: 1399px) {
         right: 70px;
      }

      @media (max-width: 1098px) {
         right: 50px;
      }

      @media (max-width: 991px) {
         right: 40px;
         width: 95%;
         max-width: 335px;
         bottom: 50px;
      }

      li {
         display: inline-block;
         vertical-align: top;

         &.slick-active {

            button {
               border-color: #fff;
            }
         }

         button {
            width: 25px;
            height: 25px;
            margin-left: 10px;
            position: relative;
            border-radius: 100%;
            display: block;
            border: 1px solid #fff;
            border-color: transparent;
            background: none;
            font-size: 0px;
            outline: none;

            &:before {
               top: 50%;
               left: 50%;
               width: 6px;
               height: 6px;
               content: "";
               position: absolute;
               border-radius: 100%;
               background-color: #fff;
               transform: translate(-50%, -50%);
               -webkit-transform: translate(-50%, -50%);
               -moz-transform: translate(-50%, -50%);
            }
         }
      }
   }
}

.collection-slider__item {
   min-height: 100vh;
   height: 1080px;
   position: relative;
   display: none !important;
   background: #795d4b;

   &:first-of-type {
      display: block !important;
   }

   @media (max-width: 1199px) {
      height: 1000px;
   }

   @media (max-width: 991px) {
      height: 720px;
   }
}

/* Image */
.collection-slider__image {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;

   img {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (max-width: 991px) {
         object-position: 40%;
      }

      &.active {
         display: block;
      }
   }
}

/* Current slider */
.collection-slider__current-slider {
   position: absolute;
   bottom: 75px;
   left: 75px;
   z-index: 9;
   text-align: center;

   @media (max-width: 1399px) {
      left: 65px;
   }

   @media (max-width: 1098px) {
      left: 45px;
   }

   @media (max-width: 991px) {
      display: none;
   }

   span {
      display: block;
      color: #a4846e;
      font-size: 36px;
      font-weight: 300;
      padding-right: 3px;

      &.current-slider {
         color: #fff;
      }
   }

   svg {

      path {
         fill: #fff !important;
         opacity: 0.25;
      }
   }
}

.collection-slider {

   .slick-prev {
      line-height: 1;
      opacity: 1;
      left: auto;
      top: auto;
      right: 190px;
      bottom: 65px;
      padding: 0px;
      margin: 0px;
      transform: none;
      -webkit-transform: none;
      -moz-transform: none;

      @media (max-width: 1399px) {
         right: 180px;
      }

      @media (max-width: 1098px) {
         right: 160px;
      }

      @media (max-width: 991px) {
         display: none !important;
      }

      &:before {
         content: "\ecb2";
         font-size: 48px;
      }
   }  

   .slick-next {
      line-height: 1;
      opacity: 1;
      left: auto;
      top: auto;
      right: 80px;
      bottom: 65px;
      padding: 0px;
      margin: 0px;
      transform: none;
      -webkit-transform: none;
      -moz-transform: none;

      @media (max-width: 1399px) {
         right: 70px;
      }

      @media (max-width: 1098px) {
         right: 50px;
      }

      @media (max-width: 991px) {
         display: none !important;
      }

      &:before {
         content: "\ecb6";
         font-size: 48px;
      }
   }

   .slick-dots {
      top: 50%;
      left: 80px;
      right: auto;
      bottom: auto;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);

      @media (max-width: 1399px) {
         left: 70px;
      }

      @media (max-width: 1098px) {
         left: 50px;
      }

      @media (max-width: 991px) {
         left: 20px;
         top: auto;
         right: auto;
         bottom: 25px;
      }

      li {
         display: block;

         @media (max-width: 991px) {
            display: inline-block;
         }

         button {
            margin: 10px 0px;

            @media (max-width: 991px) {
               margin-right: 10px;
            }
         }
      }
   }

   .collection-slider__item {
      background: #a1acab;
   }

   .collection-slider__current-slider svg path {
      opacity: 0.5;
   }

   .collection-slider__current-slider span {
      color: #fff;
   }
}

.collection-slider__short-description {
   color: #fff;
   left: 240px;
   bottom: 75px;
   position: absolute;
   width: 80%;
   max-width: 480px;

   @media (max-width: 1640px) {
      left: 220px;
   }

   @media (max-width: 1399px) {
      left: 200px;
   }

   @media (max-width: 1098px) {
      left: 160px;
   }

   @media (max-width: 991px) {
      left: 20px;
      right: 20px;
      bottom: 120px
   }

   span {
      font-weight: bold;
      text-transform: uppercase;
   }

   p {
      padding: 40px 0px 0px 0px;
      font-size: 24px;
      line-height: 1.5;
      margin: 0px;

      @media (max-width: 991px) {
         font-size: 16px;
         padding-top: 20px;
      }
   }
}

.collection-slider__content {
   z-index: 2;
   color: #fff;
   margin: 0;
   max-width: 760px;
   position: absolute;
   left: 50%;
   top: 48%;
   width: 80%;
   transform: translate(-50%, -50%);
   -webkit-transform: translate(-50%, -50%);
   -moz-transform: translate(-50%, -50%);
   text-align: center;

   @media (max-width: 991px) {
      top: 44%;
   }
}

.collection-slider__title {
   font-size: 150px;
   line-height: 1;
   color: #fff;
   text-transform: uppercase;
   padding: 0px;
   margin: 0px;
   opacity: 0;
   transform: translateY(50px);

   @media (max-width: 1098px) {
      font-size: 120px;
   }

   @media (max-width: 991px) {
      font-size: 55px;
   }
}

.slick-active {

   .collection-slider__title.is-active {
      opacity: 1;
      transform: translateY(0);
      transition: all 1000ms ease;
      transition-delay: 500ms;
   }
}

.collection-slider__buttons {
   padding: 65px 0px 0px 0px;
   opacity: 0;
   transform: translateY(50px);

   @media (max-width: 991px) {
      padding-top: 45px;
   }

   a {
      display: inline-block;
      margin: 0px 5px;
      padding: 14px 35px;
      text-align: center;
      background: #fff;
      border: 1px solid #fff;
      color: #000;
      letter-spacing: 0.75px;
      transition: 0.3s all ease-in-out;
      -webkit-transition: 0.3s all ease-in-out;
      -moz-transition: 0.3s all ease-in-out;
      text-transform: uppercase;

      @media (max-width: 991px) {
         padding: 9px 27px;
      }

      &.without-background {
         background: none;
         color: #fff;

         &:hover {
            background: #fff;
            color: #000;
         }
      }
   }
}

.slick-active {

   .collection-slider__buttons.is-active {
      opacity: 1;
      transform: translateY(0);
      transition: all 1000ms ease;
      transition-delay: 700ms;
   }
}
