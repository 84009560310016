/* ==========================================================================
   11.4 #FOOTER TYPE 2
   ========================================================================== */
.footer--type-2 {
   background: #f3f6f8 !important;
   border: none !important;

   .footer__newsleter-input {
      border-width: 1px !important;
   }

   .footer__newsletter-button {
      background: #503a2a !important;
   }

   .copyright {
      border-color: #dedede !important;
   }
}