/* ==========================================================================
   #HR ELEMENT
   ========================================================================== */
hr {
   background: #e6e6e6;
   height: 1px;
   border: none;
   padding: 0px;
   margin: 0px;
   opacity: 1;

   &.transparent {
      background: transparent;
   }
}