/* ==========================================================================
   11.5 #HEADER - VERSION 2
   ========================================================================== */
#app > div:not(.absolute-header) {

   .header--type-2 {
      border-color: rgba(0, 0, 0, 0.07);
      position: relative;


      .mobile-menu__open {

         a {
            color: #000;
         }
      }

      .header__logo {

         a {
            color: #000;
         }
      }

      .cart__total-price {
         color: #000;
      }

      .header__canvas-menu:before {
         background: rgba(0, 0, 0, 0.07);
      }

      .header__right {

         > li {

            > a {
               color: #000;

               span {
                  background: #000;
               }
            }
         }
      }
   }
}

.header--type-2 {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   padding: 40px 0px 35px;
   border-bottom: 1px solid rgba(255, 255, 255, 0.07);

   @media (max-width: 991px) {
      margin: 0px 20px;
      padding: 20px 0px 18px 0px;

      .container--type-3 {
         padding: 0px;
      }
   }

   .header__container {
      padding: 0px;
      margin: 0px;
      border: none;
   }

   .mobile-menu__open {

      a {
         color: #000;
      }
   }

   .header__logo {

      a {
         color: #fff;
      }
   }

   .header__right {
      margin-left: auto;

      > li {

         > a {
            color: #000;

            span {
               background: #000;
            }
         }
      }
   }

   .header__cart {

      &:before {
         display: none !important;
      }

      span {
         left: 10px;
         right: auto;
      }
   }

   .cart__total-price {
      position: relative;
      margin: 0px;
      padding: 0px;
      background: none !important;
      color: #fff;
      border-radius: 0px;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      display: inline-block;
      width: auto;
      height: auto;
      font-size: 16px;
      margin-left: 15px;

      @media (max-width: 991px) {
         display: none;
      }
   }

   .header__canvas-menu {
      margin-left: 110px;
      position: relative;

      @media (max-width: 991px) {
         display: none;
      }

      &:before {
         top: 50%;
         left: -55px;
         width: 1px;
         content: "";
         height: 50px;
         position: absolute;
         background-color: rgba(255, 255, 255, 0.07);
         display: block;
         transform: translateY(-50%);
         -webkit-transform: translateY(-50%);
         -moz-transform: translateY(-50%);
      }

      a {
         font-size: 22px;
      }
   }

   .mobile-menu__content {
      background: #fff;
   }

   .mobile-menu__logo {
      color: #000;
   }

   .mobile-menu__close a {
      color: #000;
   }

   .mobile-menu__nav > li {
      border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
   }

   .mobile-menu__nav > li > a {
      color: #000;
   }

   .mobile-menu__dropdown-btn {
      color: rgba(0, 0, 0, 0.5);
      border-color: rgba(0, 0, 0, 0.5);
   }

   .mobile-menu__dropdown-menu li a {
      color: #000;
   }
}