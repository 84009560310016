/* ==========================================================================
   8.1 #ABOUT PAGE
   ========================================================================== */
.about-page {

}

/* Title */
.about-page__title {
   padding: 90px 0px 30px 0px;
   max-width: 750px;
   margin: 0px auto;
   text-align: center;
   font-size: 48px;

   @media (max-width: 1098px) {
      font-size: 40px;
   }

   @media (max-width: 991px) {
      padding: 60px 0px 25px 0px;
      font-size: 26px;
   }
}

/* Description */
.about-page__description {
   padding: 0px 0px 80px 0px;
   max-width: 750px;
   margin: 0px auto;
   text-align: center;

   @media (max-width: 991px) {
      padding: 0px 0px 50px 0px;
   }
}

/* Image */
.about-page__image {

   img {
      display: block;
      width: 100%;
   }
}

/* Features */
.about-page__features {
   max-width: 1340px;
   margin: 0px auto;
   padding: 70px 0px 60px 0px;

   @media (max-width: 991px) {
      padding: 35px 20px 30px 20px;

      .row {
         flex-wrap: nowrap;
         overflow-x: scroll;

         > div {
            width: 75%;
         }
      }
   }
}

/* Feature */
.about-feature {
   display: flex;
}

.about-feature__icon {
   width: 40px;
   line-height: 1;
   min-width: 40px;
   font-size: 40px;
   color: #000;

   @media (max-width: 991px) {
      width: 26px;
      min-width: 26px;
      font-size: 26px;
   }
}

.about-feature__text {
   margin-left: 40px;
   max-width: 245px;

   @media (max-width: 1098px) {
      margin-left: 30px;
   }

   @media (max-width: 991px) {
      margin-left: 25px;
   }
}

.about-feature__title {
   padding: 0px 0px 20px 0px;
   margin: 0px;
   font-family: "Pretendard";
   font-size: 22px;
   color: #000;
   text-transform: uppercase;

   @media (max-width: 1098px) {
      font-size: 20px;
   }

   @media (max-width: 991px) {
      font-size: 14px;
      padding-bottom: 15px;
   }
}

.about-feature__description {
   color: #999999;
   font-size: 16px;
   line-height: 1.667;

   @media (max-width: 991px) {
      font-size: 12px;
   }
}

/* About company */
.about-company {
   padding: 145px 0px 110px 0px;
   background: #ececec;

   @media (max-width: 991px) {
      padding: 10px 0px 80px 0px;
   }

   .row {
      margin: 0px -45px;

      @media (max-width: 1098px) {
         margin: 0px -15px;
      }

      > div {
         padding: 0px 45px;

         @media (max-width: 1098px) {
            padding: 0px 15px;
         }
      }
   }
}

.about-company__item {

   @media (max-width: 991px) {
      padding-top: 60px !important;
   }
}

.about-company__image {
   margin: 0px 0px 70px 0px;

   @media (max-width: 991px) {
      margin: 0px 0px 50px 0px;
   }

   img {
      display: block;
      width: 100%;
   }
}

.about-company__title {
   color: #000000;
   font-weight: 400;
   font-size: 36px;
   font-family: "Pretendard";
   padding: 0px 0px 40px 0px;
   margin: 0px;
   text-transform: uppercase;

   @media (max-width: 1098px) {
      font-size: 30px;
   }

   @media (max-width: 991px) {
      font-size: 21px;
      padding: 0px 0px 25px 0px;
   }
}

.about-company__description {

   p {
      padding: 0px 0px 30px 0px;
      margin: 0px;

      @media (max-width: 991px) {
         padding: 0px 0px 25px 0px;
      }

      &:last-child {
         padding-bottom: 0px;
      }

      span {
         font-size: 24px;
         color: #000;
         line-height: 1.5;
         display: block;
         margin-bottom: 5px;

         @media (max-width: 1098px) {
            font-size: 21px;
         }

         @media (max-width: 991px) {
            font-size: 18px;
         }
      }

      strong {
         font-size: 14px;
         text-transform: uppercase;
         font-weight: 400;
         color: #000;
         letter-spacing: 0.5px;

         @media (max-width: 991px) {
            font-size: 12px;
         }
      }
   }
}

/* About section */
.about-section {
   padding: 0px 0px 120px 0px;

   @media (max-width: 991px) {
      padding: 0px 0px 70px 0px;
   }

   .home-brands__items {
      border: none;
      padding: 55px 0px 0px 0px;
      margin: 0px;

      @media (max-width: 991px) {
         padding: 15px 0px 0px 0px;
      }
   }
}

.about-section__title {
   padding: 135px 0px 0px 0px;
   margin: 0px;
   font-size: 36px;
   font-family: "Pretendard";
   text-transform: uppercase;
   letter-spacing: 1px;

   @media (max-width: 1098px) {
      font-size: 30px;
   }

   @media (max-width: 991px) {
      padding: 80px 0px 0px 0px;
      font-size: 24px;
   }
}

hr + .about-section__title {
   padding-top: 120px;

   @media (max-width: 991px) {
      padding-top: 80px;
   }
}

.stores-list__item {
   padding-top: 75px;

   @media (max-width: 991px) {
      padding-top: 50px;
   }
}

.store-item__title {
   color: #000000;
   font-size: 24px;
   font-family: "Pretendard";
   padding: 0px 0px 35px 0px;
   margin: 0px;

   @media (max-width: 991px) {
      font-size: 18px;
      padding: 0px 0px 25px 0px;
   }
}

.store-item__address {
   font-size: 16px;
   line-height: 30px;

   @media (max-width: 991px) {
      font-size: 14px;
      line-height: 28px;
   }

   p {
      padding: 0px;
      margin: 0px;
   }

   p + .address__store-number {
      padding-top: 35px;

      @media (max-width: 991px) {
         padding-top: 25px;
      }
   }
}

.address__store-number {
   color: #000;
   display: block;
   padding: 0px 0px 15px 0px;
   margin: 0px;
   text-transform: uppercase;
   font-size: 14px;
   letter-spacing: 0.5px;
   font-family: "Pretendard";

   @media (max-width: 991px) {
      font-size: 12px;
   }
}