/* ==========================================================================
   8.14 #SHOP CATEGORIES
   ========================================================================== */
.shop-categories {
   padding: 0px 0px 225px 0px;

   @media (max-width: 991px) {
      padding: 0px 0px 85px 0px;
   }

   .row {
      margin: 0px -5px;

      > div {
         padding: 0px 5px;
      }
   }
}

/* Title */
.shop-categories__title {
   padding: 90px 0px 30px 0px;
   max-width: 750px;
   margin: 0px auto;
   text-align: center;
   font-size: 48px;

   @media (max-width: 1098px) {
      font-size: 40px;
   }

   @media (max-width: 991px) {
      padding: 60px 0px 25px 0px;
      font-size: 26px;
   }
}

/* Description */
.shop-categories__description {
   padding: 0px 0px 70px 0px;
   max-width: 750px;
   margin: 0px auto;
   text-align: center;

   @media (max-width: 991px) {
      padding: 0px 0px 40px 0px;
   }
}

/* Shop category */
.shop-category {
   margin: 10px 0px 0px 0px;
   position: relative;

   &:hover {

      .shop-category__shop-now {

         a {

            &:before {
               width: 100%;
            }
         }
      }
   }
}

.shop-category__image {
   overflow: hidden;

   img {
      display: block;
      width: 100%;
      height: 770px;
      object-fit: cover;

      @media (max-width: 1098px) {
         height: 650px;
      }

      @media (max-width: 991px) {
         height: 350px;
      }
   }
}

.shop-category__small-heading {
   position: absolute;
   left: 100px;
   top: 85px;
   font-size: 24px;
   line-height: 30px;
   text-transform: uppercase;
   font-family: "Pretendard";
   letter-spacing: 0.5px;

   @media (max-width: 1499px) {
      left: 70px;
   }

   @media (max-width: 1098px) {
      font-size: 21px;
      line-height: 26px;
   }

   @media (max-width: 991px) {
      font-size: 16px;
      line-height: 21px;
      left: 30px;
      top: 30px;
   }

   a {
      color: #000;
      display: block;
   }
}

.shop-category__heading-and-description {
   position: absolute;
   left: 100px;
   bottom: 90px;
   width: 50%;
   max-width: 470px;

   @media (max-width: 1499px) {
      left: 70px;
   }

   @media (max-width: 991px) {
      left: 30px;
      bottom: 30px;
   }
}

.shop-category__heading {
   font-size: 80px;
   line-height: 84px;
   padding: 0px;
   margin: 0px;

   @media (max-width: 1098px) {
      font-size: 64px;
      line-height: 1.2;
   }

   @media (max-width: 991px) {
      font-size: 30px;
   }

   a {
      color: #353368;
      display: block;
      padding: 0px 0px 45px 0px;

      @media (max-width: 991px) {
         padding-bottom: 20px;
      }
   }
}

.shop-category__subheading-and-heading {
   position: absolute;
   left: 100px;
   top: 70px;
   max-width: 300px;
   width: 45%;

   @media (max-width: 1499px) {
      left: 70px;
   }

   @media (max-width: 991px) {
      top: 30px;
      left: 30px;
   }
}

.shop-category__subheading {
   font-family: "Pretendard";
   font-size: 14px;
   letter-spacing: 0.5px;
   text-transform: uppercase;
   padding: 0px;
   margin: 0px;

   @media (max-width: 991px) {
      font-size: 12px;
   }

   a {
      color: #000;
      display: block;
      padding: 0px 0px 30px 0px;

      @media (max-width: 991px) {
         padding-bottom: 20px;
      }
   }
}

.shop-category__heading-2 {
   padding: 0px;
   margin: 0px;
   font-size: 48px;
   line-height: 54px;

   @media (max-width: 1098px) {
      font-size: 42px;
      line-height: 1.2;
   }

   @media (max-width: 991px) {
      font-size: 26px;
   }

   a {
      color: #000;
   }
}

.shop-category__description {

   a {
      color: #666;
   }
}

.shop-category__shop-now {
   position: absolute;
   left: 100px;
   bottom: 125px;

   @media (max-width: 1499px) {
      left: 70px;
   }

   @media (max-width: 1098px) {
      bottom: 75px;
   }

   @media (max-width: 991px) {
      left: 30px;
      bottom: 30px;
   }

   a {
      display: inline-block;
      font-size: 20px;
      line-height: 3;
      color: #000;
      position: relative;

      @media (max-width: 1098px) {
         font-size: 16px;
      }

      @media (max-width: 991px) {
         font-size: 15px;
      }

      &:before {
         content: '';
         display: block;
         position: absolute;
         bottom: 0;
         left: 0;
         width: 40%;
         height: 1px;
         background: #000;
         transition: all 0.3s ease-in;
         -webkit-transition: all 0.3s ease-in;
         -moz-transition: all 0.3s ease-in;
      }
   }
}

.shop-category--medium {

   .shop-category__image {

      img {
         height: 700px;

         @media (max-width: 1098px) {
            height: 600px;
         }

         @media (max-width: 991px) {
            height: 300px;
         }
      }
   }

   .shop-category__heading-and-description {
      max-width: 310px;
   }

   .shop-category__heading {
      font-size: 60px;
      line-height: 1.2;

      @media (max-width: 1098px) {
         font-size: 48px;
      }

      @media (max-width: 991px) {
         font-size: 30px;
      }
   }
}

.shop-category--small {

   .shop-category__image {

      img {
         height: 550px;

         @media (max-width: 1098px) {
            height: 480px;
         }

         @media (max-width: 991px) {
            height: 250px;
         }
      }
   }
}

.shop-category--full-height {

   .shop-category__image {

      img {
         height: 1080px;
         min-height: 100vh;

         @media (max-width: 1098px) {
            height: 920px;
         }

         @media (max-width: 991px) {
            height: 600px;
            min-height: 80vh;
         }
      }
   }
}