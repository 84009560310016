/* ==========================================================================
   #ACCORDION
   ========================================================================== */
.accordion {
   padding: 22px 0px;
   border-bottom: 1px solid #d8d8d8;

   &.active {

      .accordion__content {
         display: block;
      }

      .accordion__title {

         &:after {
            transform: translateY(-50%) rotate(-90deg);
            -webkit-transform: translateY(-50%) rotate(-90deg);
            -moz-transform: translateY(-50%) rotate(-90deg);
         }
      }
   }
}

.accordion__title {
   font-size: 13px;
   color: #000;
   text-transform: uppercase;
   letter-spacing: 0.5px;
   position: relative;
   padding-right: 30px;

   &:after {
      content: "";
      display: inline-block;
      font: normal normal normal 1em/1 "LineIconsPro Light";
      speak: none;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: block;
      font-size: 13px;
      position: absolute;
      top: 50%;
      right: 0px;
      color: #000;
      transform: translateY(-50%) rotate(90deg);
      -webkit-transform: translateY(-50%) rotate(90deg);
      -moz-transform: translateY(-50%) rotate(90deg);
      pointer-events: none;
   }
}

.accordion__content {
   display: none;
}

/* Mobile tabs */
.product__mobile-tabs {
   display: none;

   @media (max-width: 991px) {
      display: block;
      padding-top: 22px;
   }
}