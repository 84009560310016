/* ==========================================================================
   8.3 #BLOG WITH SIDEBAR PAGE
   ========================================================================== */
.blog-with-sidebar {
   padding: 0px 0px 150px 0px;

   @media (max-width: 991px) {
      padding: 0px 0px 85px 0px;
   }

   .container {

      .container {

         @media (max-width: 991px) {
            padding: 0px;
         }
      }
   }
}

.promo-article {
   position: relative;
}

.promo-article__image {

   a {
      display: block;

      img {
         display: block;
         width: 100%;
         height: 35vw;
         min-height: 400px;
         object-fit: cover;

         @media (max-width: 991px) {
            min-height: 300px;
         }
      }
   }
}

.promo-article__details {
   position: absolute;
   left: 0;
   right: 0;
   bottom: 75px;

   @media (max-width: 1098px) {
      bottom: 50px;
   }

   @media (max-width: 991px) {
      bottom: 25px;
   }
}

.promo-article__meta {
   list-style: none;
   padding: 0px 0px 20px 0px;
   margin: 0px;

   @media (max-width: 991px) {
      padding: 0px 0px 9px 0px;
   }

   li {
      display: inline-block;
      color: #fff;
      font-size: 14px;

      @media (max-width: 991px) {
         font-size: 13px;
      }

      &:first-child:before {
         display: none;
      }

      &:before {
         content: '/';
         display: inline-block;
         vertical-align: top;
         padding: 0px 6px 0px 2px;
      }

      a {
         color: #fff;
         text-transform: uppercase;
         letter-spacing: 0.5px;
      }
   }
}

.promo-article__title {
   line-height: 46px;
   font-size: 36px;
   padding: 0px;
   margin: 0px;

   @media (max-width: 1098px) {
      font-size: 30px;
      line-height: 38px;
   }

   @media (max-width: 991px) {
      font-size: 18px;
      line-height: 24px;
   }

   a {
      color: #fff;
   }
}

/* Newsletter */
.blog-with-sidebar__newsletter {

}

.blog-newsletter {
   padding: 100px 0px 90px 0px;

   @media (max-width: 991px) {
      padding: 70px 0px;
   }
}

.blog-newsletter__title {
   padding: 0px;
   margin: 0px;
   font-size: 36px;

   @media (max-width: 1098px) {
      font-size: 30px;
   }

   @media (max-width: 991px) {
      font-size: 24px;
      padding: 0px 0px 20px 0px;
   }
}

.blog-newsletter__form {
   position: relative;
}

.blog-newsletter__input {
   display: block;
   width: 100%;
   padding: 5px 0px;
   background: none;
   border: none;
   border-bottom: 1px solid #c1c5cd;
   outline: none;
}

.blog-newsletter__submit {
   display: block;
   background: none;
   border: none;
   position: absolute;
   top: 50%;
   right: 0;
   transform: translateY(-50%);
   -webkit-transform: translateY(-50%);
   -moz-transform: translateY(-50%);
   font-size: 16px;
   text-transform: uppercase;
   letter-spacing: 0.5px;
   color: #000;

   @media (max-width: 991px) {
      font-size: 14px;
   }
}

/* Left column */
.blog-with-sidebar__left {
   padding-top: 50px !important;

   @media (max-width: 991px) {
      padding-top: 25px !important;
   }

   hr {
      margin-top: 55px;
      margin-bottom: 15px;
   }
}

/* Article item */
.blog-article-2 {
   padding-top: 55px;
}

.blog-article-2__image {
   padding-bottom: 10px;

   a {
      display: block;

      img {
         display: block;
         width: 100%;
      }
   }
}

.blog-article-2__meta {
   list-style: none;
   padding: 15px 0px 0px 0px;
   margin: 0px;

   @media (max-width: 991px) {
      padding-top: 10px;
   }

   li {
      display: inline-block;
      vertical-align: top;
      color: #999;
      font-size: 14px;

      @media (max-width: 991px) {
         font-size: 11px;
      }

      &:first-child:before {
         display: none;
      }

      &:before {
         content: '/';
         display: inline-block;
         vertical-align: top;
         padding: 0px 6px 0px 2px;
      }

      a {
         color: #000;
         text-transform: uppercase;
         letter-spacing: 0.5px;
      }
   }
}

.blog-article-2__title {
   padding: 5px 0px 0px 0px;
   margin: 0px;
   font-size: 24px;
   color: #000;
   line-height: 1.5;

   @media (max-width: 1098px) {
      font-size: 21px;
   }

   @media (max-width: 991px) {
      font-size: 18px;
   }

   a {
      color: #000;
      letter-spacing: 0.5px;

      &:hover {
         color: #928656;
      }
   }
}

.blog-article-2__description {
   padding: 15px 0px 0px 0px;
   margin: 0px;
   font-size: 16px;
   line-height: 26px;

   @media (max-width: 991px) {
      font-size: 14px;
      line-height: 24px;
   }
}

/* Blog articles type 2 */
#main .blog__articles--type-2 {
   margin: 0px -35px;

   @media (max-width: 1098px) {
      margin: 0px -15px;
   }

   > div {
      padding: 0px 35px;

      @media (max-width: 1098px) {
         padding: 0px 15px;
      }
   }

   .blog-article {
      margin-top: 55px;
      margin-bottom: 0px;

      @media (max-width: 991px) {
         margin-top: 45px;
      }
   }
}

/* Blog article type 3 */
.blog-article-3 {
   display: flex;
   padding-top: 55px;

   @media (max-width: 767px) {
      flex-wrap: wrap;
      padding-top: 45px;
   }
}

.blog-article-3__image {
   width: 46.5%;
   min-width: 46.5%;
   margin-right: 50px;

   @media (max-width: 1098px) {
      margin-right: 30px;
   }

   @media (max-width: 767px) {
      width: 100%;
      min-width: 100%;
      margin: 0px 0px 15px 0px;
   }

   a {
      display: block;

      img {
         display: block;
         width: 100%;
      }
   }
}

.blog-article-3__right {
   width: 100%;
}

.blog-article-3__meta {
   list-style: none;
   padding: 7px 0px 0px 0px;
   margin: 0px;

   @media (max-width: 991px) {
      padding-top: 5px;
   }

   li {
      display: inline-block;
      vertical-align: top;
      color: #999;
      font-size: 14px;

      @media (max-width: 991px) {
         font-size: 11px;
      }

      &:first-child:before {
         display: none;
      }

      &:before {
         content: '/';
         display: inline-block;
         vertical-align: top;
         padding: 0px 6px 0px 2px;
      }

      a {
         color: #000;
         text-transform: uppercase;
         letter-spacing: 0.5px;
      }
   }
}

.blog-article-3__title {
   padding: 5px 0px 10px 0px;
   margin: 0px;
   font-size: 24px;
   color: #000;
   line-height: 1.5;

   @media (max-width: 1098px) {
      font-size: 21px;
   }

   @media (max-width: 991px) {
      font-size: 18px;
   }

   a {
      color: #000;
      letter-spacing: 0.5px;

      &:hover {
         color: #928656;
      }
   }
}

/* Pagination */
.standard-pagination {
   list-style: none;
   padding: 0px;
   margin: 105px 0px 0px 0px;
   display: flex;
   flex-wrap: wrap;

   @media (max-width: 991px) {
      margin-top: 45px;
   }

   li {
      padding: 10px 10px 0px 0px;

      a {
         font-size: 14px;
         padding: 8px 15px;
         display: block;
         border: 1px solid #dfdfdf;
         text-transform: uppercase;
         transition: all 0.3s ease-in;
         -webkit-transition: all 0.3s ease-in;
         -moz-transition: all 0.3s ease-in;

         @media (max-width: 991px) {
            font-size: 13px;
            padding: 5px 12px;
         }

         &:hover,
         &.active {
            background: #928656;
            border-color: #928656;
            color: #fff;
         }
      }
   }
}

/* Column right */
.blog-with-sidebar__right {
   padding-left: 85px !important;
   padding-top: 38px !important;

   @media (max-width: 1098px) {
      padding-left: 25px !important;
   }

   @media (max-width: 991px) {
      padding-left: 15px !important;
      padding-top: 15px !important;
   }
}



/* Blog widget */
.blog-widget {
   padding: 65px 0px 0px 0px;

   @media (max-width: 991px) {
      padding-top: 50px;
   }
}

.blog-widget__title {
   font-size: 20px;
   font-family: "Pretendard";
   font-weight: 400;
   text-transform: uppercase;
   letter-spacing: 1px;
   padding: 0px 0px 20px 0px;
   margin: 0px;

   @media (max-width: 1098px) {
      font-size: 18px;
   }

   @media (max-width: 991px) {
      font-size: 17px;
   }
}

/* Blog widget - search */
.blog-widget{

   .blog__search {
      margin: 0px;
      padding: 0px;
      width: 100%;

      .blog-search__input {
         padding-left: 0px;
         padding-right: 30px;
      }

      .blog-search__button {
         left: auto;
         right: 0;
      }
   }
}

/* Blog widget - socials */
.blog-widget__socials {
   display: flex;
   flex-wrap: wrap;
   list-style: none;
   padding: 0px;
   margin: -5px 0px 0px 0px;

   li {
      margin: 10px 10px 0px 0px;
      padding: 0px;

      a {
         display: block;
         width: 40px;
         height: 40px;
         border: 1px solid #cdcdcd;
         border-radius: 100px;
         -webkit-border-radius: 100px;
         -moz-border-radius: 100px;
         line-height: 38px;
         text-align: center;
         font-size: 18px;
         transition: all 0.3s ease-in;
         -webkit-transition: all 0.3s ease-in;
         -moz-transition: all 0.3s ease-in;

         @media (max-width: 991px) {
            width: 36px;
            height: 36px;
            line-height: 34px;
            font-size: 16px;
         }

         &:hover {
            background: #928656;
            border-color: #928656;
            color: #fff;
         }
      }
   }
}

/* Blog widget - popular posts */
.blog-widget__popular-posts {
   list-style: none;
   padding: 0px;
   margin: 0px;
   
   li {
      border-top: 1px solid #cccccc;
      padding: 20px 0px;

      &:first-child {
         padding-top: 10px;
         border-top: none;
      }

      &:last-child {
         padding-bottom: 10px;
      }

      &:nth-child(2) {

         a {

            &:before {
               content: '2';
            }
         }
      }

      &:nth-child(3) {

         a {

            &:before {
               content: '3';
            }
         }
      }

      &:nth-child(4) {

         a {

            &:before {
               content: '4';
            }
         }
      }

      &:nth-child(5) {

         a {

            &:before {
               content: '5';
            }
         }
      }

      &:nth-child(6) {

         a {

            &:before {
               content: '6';
            }
         }
      }

      a {
         display: block;
         position: relative;

         &:before {
            content: '1';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            height: 40px;
            border-right: 1px solid #ccc;
            line-height: 40px;
            text-align: left;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            font-size: 30px;
            color: #ccc;
            font-family: "Pretendard";
            width: 32px;
         }

         &:hover {

            .popular-post__title {
               color: #928656;
            }
         }
      }
   }
}

.popular-post__category {
   padding: 0px 0px 0px 54px;
   margin: 0px;
   color: #999999;
   font-size: 12px;
   text-transform: uppercase;
   letter-spacing: 0.5px;

   @media (max-width: 991px) {
      font-size: 11px;
   }
}

.popular-post__title {
   padding: 0px 0px 0px 54px;
   margin: 0px;
   font-size: 18px;
   color: #000;
   font-family: "Pretendard";
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   transition: all 0.3s ease-in;
   -webkit-transition: all 0.3s ease-in;
   -moz-transition: all 0.3s ease-in;

   @media (max-width: 991px) {
      font-size: 16px;
   }
}

/* Blog widget - instagram */
.blog-widget {

   @media (min-width: 992px) {

      .instagram_feed {
         flex-wrap: wrap;
         padding-top: 20px;

         li {
            width: 33.333%;
            min-width: 33.333%;
            padding-top: 10px;

            a {

               i {
                  font-size: 24px;
               }
            }
         }
      }
   }

   @media (max-width: 991px) {

      .instagram_feed {
         padding-top: 30px;
      }
   }
}