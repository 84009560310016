/* ==========================================================================
   10.3 #SEARCH POPUP
   ========================================================================== */
.search-popup {
   background: #fff;
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   z-index: 999;
   padding: 60px 0px;
   overflow-y: scroll;
   opacity: 0;
   visibility: hidden;
   pointer-events: none;
   transition: all 0.3s ease-in;
   -webkit-transition: all 0.3s ease-in;
   -moz-transition: all 0.3s ease-in;

   @media (max-width: 991px) {
      padding: 30px 0px 50px 0px;
   }

   &.active {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
   }
}

/* Search close */
.search-popup__close {
   position: absolute;
   top: 40px;
   right: 55px;

   @media (max-width: 991px) {
      top: 0;
      right: 0;
   }

   a {
      display: block;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
   }
}

/* Search title */
.search-popup__title {
   font-size: 30px;
   padding: 0px;
   margin: 0px;

   @media (max-width: 991px) {
      font-size: 22px;
   }
}

/* Search categories */
.search-popup__categories {
   padding: 40px 0px 0px 0px;
   margin: 0px;
   list-style: none;
   text-align: center;

   li {
      display: inline-block;
      vertical-align: top;
      margin: 0px 20px;

      @media (max-width: 991px) {
         margin: 0px 8px;
      }

      a {
         font-size: 16px;
         letter-spacing: 0.5px;
         text-transform: uppercase;
         color: #000;

         @media (max-width: 991px) {
            font-size: 14px;
         }

         &.active {
            color: #928656;
            text-decoration: underline;
         }
      }
   }
}

/* Search form */
.search-popup__form {
   padding: 40px 0px 0px 0px;
   margin: 0px;
}

/* Search form input */
.search-popup__input {
   display: block;
   width: 100%;
   color: #000;
   border: none;
   padding: 5px 0px;
   background-color: #fff;
   font-size: 30px;
   border-bottom: 1px solid #000;
   outline: none;

   @media (max-width: 991px) {
      font-size: 22px;
   }

   &::-webkit-input-placeholder {
      color: #000;
   }

   &::-moz-placeholder {
      color: #000;
   }

   &:-ms-input-placeholder {
      color: #000;
   }

   &:-moz-placeholder {
      color: #000;
   }
}

/* Search results */
.search-popups__results {
   padding-top: 55px;

   @media (max-width: 991px) {
      padding-top: 30px;
   }
}

/* Search results heading */
.search-popup__results-heading {
   font-size: 14px;
   color: #999999;
   text-transform: uppercase;
   letter-spacing: 0.5px;
   padding: 0px;
   margin: 0px;
}

/* Search results products */
.search-popups__results-products {
   margin: 30px -25px 0px -25px;
   position: relative;
   overflow: hidden;

   @media (max-width: 1399px) {
      margin-left: -20px;
      margin-right: -20px;
   }

   @media (max-width: 991px) {
      margin-left: -5px;
      margin-right: -5px;
      margin-top: 25px;
   }
}

/* Search result product */
.result-product {
   width: 20%;
   min-width: 20%;
   padding: 0px 25px;

   @media (max-width: 1399px) {
      padding: 0px 20px;
      width: 25%;
      min-width: 25%;
   }

   @media (max-width: 991px) {
      padding: 0px 5px;
      width: 50%;
      min-width: 50%;
   }
}

.result-product__image {

   a {
      display: block;
      padding-bottom: 139.948%;
      position: relative;

      img {
         display: block;
         width: 100%;
         height: 100%;
         left: 0;
         right: 0;
         bottom: 0;
         top: 0;
         position: absolute;
         object-fit: cover;
      }
   }
}

.result-product__image--ratio-100-122 {

   a {
      padding-bottom: 122%;
   }
}

.result-product__name {
   padding-top: 25px;

   @media (max-width: 991px) {
      padding-top: 15px;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
   }

   a {
      color: #000;

      &:hover {
         color: #928656;
      }
   }
}

.result-product__price {
   padding: 7px 0px 0px 0px;
   color: #000;

   @media (max-width: 991px) {
      padding: 2px 0px 0px 0px;
   }
}

.result-product__price-new {
   color: #ea311d;
}

.result-product__price-old {
   color: #666666;
   text-decoration: line-through;
   padding-left: 3px;
}

/* Results action */
.search-popup__results-action {
   text-align: center;
   padding-top: 75px;

   @media (max-width: 991px) {
      padding-top: 40px;
   }
}