/* ==========================================================================
   7.3 #HOME BRANDS
   ========================================================================== */
.home-brands__background {
   background: #fff;
   position: relative;

   &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 100%;
      bottom: 0;
      width: 100%;
      background: #fff;
   }
}

.home-brands__items {
   padding: 85px 0px 75px;
   display: flex;
   align-items: center;
   border-bottom: 1px solid #e6e6e6;
   
   @media (max-width: 991px) {
      padding: 10px 0px 35px 0px;
      flex-wrap: wrap;
      margin-right: -35px;
      position: relative;
   }
}

.home-brand-item {
   margin: 0px auto;
   padding: 0px 30px;

   &:first-of-type {
      margin-left: 0px;
      padding-left: 0px;
   }

   &:last-of-type {
      margin-right: 0px;
      padding-right: 0px;
   }

   @media (max-width: 991px) {
      padding: 20px 5% 0px 5% !important;
      width: 33.3333%;
      min-width: 33.3333%;
      text-align: center;
      margin: 0px;

      &:nth-child(3n+3) {
         padding-right: 0 !important;
         padding-left: 10% !important;
         text-align: center;
      }

      &:nth-child(3n+1) {
         padding-right: 10% !important;
         padding-left: 0px !important;
         text-align: left;
      }
   }

   a {
      display: block;
      opacity: 0.4;
      transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;

      &:hover {
         opacity: 1;
      }

      img {
         display: inline-block;
         max-width: 100%;

         @media (max-width: 991px) {
            max-height: 55px;
            margin: 0px auto;
         }
      }
   }
}