/* Circle Monogram */
@font-face {
  font-family: 'Circle Monogram Left';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/font/doha/Circle Monogram Left Regular.ttf?#iefix') format('truetype');
}
@font-face {
  font-family: 'Circle Monogram Mid';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/font/doha/Circle Monogram Mid Regular.ttf?#iefix') format('truetype');
}
@font-face {
  font-family: 'Circle Monogram Right';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/font/doha/Circle Monogram Right Regular.ttf?#iefix') format('truetype');
}

/* NotoSerif */
@font-face {
  font-family: 'NotoSerif';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/font/marrakech/NotoSerif-Bold_M2.ttf?#iefix') format('truetype');
}

/* Staatliches */
@font-face {
  font-family: 'Staatliches';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/font/sliema/Staatliches_M3.ttf?#iefix') format('truetype');
}

/* academy */
@font-face {
  font-family: 'academy';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/font/cambridge/academy_I1.ttf?#iefix') format('truetype');
}

/* Leckerli */
@font-face {
  font-family: 'Leckerli';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/font/paris/Leckerli_One_L1_F.ttf?#iefix') format('truetype');
}

/* Cooper */
@font-face {
  font-family: 'Cooper';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/font/seoul/Cooper Black_L2_F.ttf?#iefix') format('truetype');
}

/* Big John */
@font-face {
  font-family: 'Big John';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/font/miami/Big John_L3_F.ttf?#iefix') format('truetype');
}

/* Berlin Sans */
@font-face {
  font-family: 'Berlin Sans';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/font/berlin/Berlin Sans_L4_F.ttf?#iefix') format('truetype');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 900;
  font-display: swap;
  src: local('Pretendard Black'), url('../assets/font/pretendard/Pretendard-Black.woff2') format('woff2'), url('../assets/font/pretendard/Pretendard-Black.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 800;
  font-display: swap;
  src: local('Pretendard ExtraBold'), url('../assets/font/pretendard/Pretendard-ExtraBold.woff2') format('woff2'), url('../assets/font/pretendard/Pretendard-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  font-display: swap;
  src: local('Pretendard Bold'), url('../assets/font/pretendard/Pretendard-Bold.woff2') format('woff2'), url('../assets/font/pretendard/Pretendard-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  font-display: swap;
  src: local('Pretendard SemiBold'), url('../assets/font/pretendard/Pretendard-SemiBold.woff2') format('woff2'), url('../assets/font/pretendard/Pretendard-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-display: swap;
  src: local('Pretendard Medium'), url('../assets/font/pretendard/Pretendard-Medium.woff2') format('woff2'), url('../assets/font/pretendard/Pretendard-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  font-display: swap;
  src: local('Pretendard Regular'), url('../assets/font/pretendard/Pretendard-Regular.woff2') format('woff2'), url('../assets/font/pretendard/Pretendard-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 300;
  font-display: swap;
  src: local('Pretendard Light'), url('../assets/font/pretendard/Pretendard-Light.woff2') format('woff2'), url('../assets/font/pretendard/Pretendard-Light.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 200;
  font-display: swap;
  src: local('Pretendard ExtraLight'), url('../assets/font/pretendard/Pretendard-ExtraLight.woff2') format('woff2'), url('../assets/font/pretendard/Pretendard-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 100;
  font-display: swap;
  src: local('Pretendard Thin'), url('../assets/font/pretendard/Pretendard-Thin.woff2') format('woff2'), url('../assets/font/pretendard/Pretendard-Thin.woff') format('woff');
}
