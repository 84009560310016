/* ==========================================================================
   #CONTAINER
   ========================================================================== */
.container, 
.container-lg, 
.container-md, 
.container-sm, 
.container-xl, 
.container-xxl {
  max-width: 1370px;
  padding: 0px 100px;

  @media (max-width: 1399px) {
    padding: 0px 70px;
  }

  @media (max-width: 1098px) {
    padding: 0px 50px;
  }

  @media (max-width: 991px) {
    padding: 0px 20px;

    .container {
      padding: 0px 35px;
    }
  }
}

.container--type-2 {
  max-width: 1760px;
}

.container--type-3 {
  max-width: 100%;
  padding: 0px 80px;

  @media (max-width: 1399px) {
    padding: 0px 70px;
  }

  @media (max-width: 1098px) {
    padding: 0px 50px;
  }

  @media (max-width: 991px) {
    padding: 0px 20px;
  }
}

.container--type-4 {
  max-width: 1640px;
}

.container--type-5 {
  max-width: 1920px;
  padding: 0px 80px;

  @media (max-width: 1399px) {
    padding: 0px 70px;
  }

  @media (max-width: 1098px) {
    padding: 0px 50px;
  }

  @media (max-width: 991px) {
    padding: 0px 20px;
  }
}