/* ==========================================================================
   17.3 #HOME SIDEBAR HEADER
   ========================================================================== */
.home-sidebar {

   #main {
      padding-left: 376px;
      overflow: visible;

      @media (max-width: 1499px) {
         padding-left: 300px;
      }

      @media (max-width: 1199px) {
         padding-left: 0px;
         overflow: hidden;
      }
   }
}

.sidebar-header--mobile {
   display: none;
   border-bottom: 1px solid #dbdbdb;

   @media (max-width: 1199px) {
      display: block;
   }

   .header__mobile-menu {
      display: block;
   }

   .mobile-menu {
      display: block;
   }

   .header__container {
      border: none;
      padding-top: 18px;
      padding-bottom: 17px;
   }

   .header__right {
      margin-left: auto;
   }

   .header__right > li > a span {
      background: #da5e3a;
   }

   .mobile-menu__overlay {
      background: rgba(0, 0, 0, 0.5);
   }

   .mobile-menu__content {
      background: #fff;
   }

   .mobile-menu__close a {
      color: #000;
   }

   .mobile-menu__logo {
      color: #000;
   }

   .mobile-menu__nav > li > a {
      color: #000;
   }

   .mobile-menu__dropdown-btn {
      border-color: rgba(0, 0, 0, 0.5);
      color: rgba(0, 0, 0, 0.5);
   }

   .mobile-menu__nav > li {
      border-color: rgba(0, 0, 0, 0.1);
   }

   .mobile-menu__dropdown-menu li a {
      color: #000;
   }
}

.sidebar-header {
   position: fixed;
   top: 0;
   left: 0;
   bottom: 0;
   width: 376px;
   border-right: 1px solid #e3e3e3;
   z-index: 990;
   padding: 70px 70px 70px 70px;

   @media (max-width: 1499px) {
      width: 300px;
      padding: 40px;
   }

   @media (max-width: 1199px) {
      display: none;
   }

   .header__search {
      width: 100%;
      padding: 0px;
      margin: 60px 0px 0px 0px;
      position: relative;
   }

   .header-search__input {
      width: 100%;
      background: none;
      outline: none;
      border: none;
      padding-right: 30px;
      padding-bottom: 7px;
      border-bottom: 1px solid #dbdbdb;
      font-size: 16px;
   }

   .header-search__button {
      right: 0;
      top: 1px;
      position: absolute;
      display: block;
      background: none;
      border: none;
      padding: 0px;
      margin: 0px;
   }

   .canvas-menu__nav {
      padding-top: 67px;

      > li {

         &:before {
            top: 300px;
            bottom: 200px;
         }
      }
   }

   .canvas-nav__item {
      font-size: 24px;
   }

   .canvas-menu__nav > li:hover .canvas-nav__item {
      color: #da5e3a;
   }
}

/* Cart */
.sidebar-header__cart {
   padding: 55px 0px 0px 0px;
   
   a {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      i {
         font-size: 30px;
         color: #000;
      }

      span {
         display: block;
         position: absolute;
         width: 20px;
         height: 20px;
         background: #da5e3a;
         border-radius: 100px;
         -webkit-border-radius: 100px;
         -moz-border-radius: 100px;
         text-align: center;
         line-height: 20px;
         font-size: 12px;
         color: #fff;
         margin: 16px 0px 0px 16px;
      }

      p {
         padding: 4px 0px 0px 25px;
         margin: 0px;
         color: #000;
      }
   }
}

/* Information menu */
.sidebar-header__information-menu {
   position: relative;
   z-index: 3;
   list-style: none;
   padding: 64px 0px 0px 0px;
   margin: 0px;

   @media (max-height: 890px) {
      display: none !important;
   }

   li {

      a {
         font-size: 14px;
         line-height: 30px;
         color: #999;
         text-transform: uppercase;
         letter-spacing: 1px;

         &:hover {
            color: #000;
         }
      }
   }
}

/* Account */
.sidebar-header__account {
   left: 70px;
   right: 70px;
   bottom: 70px;

   @media (max-width: 1499px) {
      left: 40px;
      right: 40px;
      bottom: 40px;
   }

   i {
      font-size: 30px;
      color: #000;
      margin-right: 23px;
      display: inline-block;
      vertical-align: top;
   }

   a {
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      line-height: 30px;
      color: #999;
      text-transform: uppercase;
      letter-spacing: 1px;

      &:hover {
         color: #000;
      }
   }

   a + a {

      &:before {
         content: '|';
         display: inline-block;
         font-size: 14px;
         color: #999;
         padding: 0px 9px 0px 5px;
      }
   }
}