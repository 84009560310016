/* ==========================================================================
   #STICKY ADD TO CART
   ========================================================================== */
.sticky-add-to-cart {
   position: fixed;
   bottom: 0;
   left: 0;
   right: 0;
   z-index: 10;
   background: #fff;
   border-top: 1px solid #dedede;
   padding: 10px 0px 10px 0px;
   opacity: 0;
   visibility: hidden;
   pointer-events: none;
   transition: all 0.3s ease-in;
   -webkit-transition: all 0.3s ease-in;
   -moz-transition: all 0.3s ease-in;

   &.active {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
   }

   @media (max-width: 1599px) {
      display: none !important;
   }
}

/* Product image */
.sticky-add-to-cart__product-image {
   width: 50px;

   p {
      padding: 0px 0px 120% 0px;
      margin: 0px;
      position: relative;

      img {
         display: block;
         width: 100%;
         height: 100%;
         left: 0;
         right: 0;
         bottom: 0;
         top: 0;
         position: absolute;
         object-fit: cover;
      }
   }
}

/* Product name */
.sticky-add-to-cart__product-name {
   padding-left: 19px;
   color: #000;
}

/* Product price */
.sticky-add-to-cart__product-price {
   padding-left: 30px;
   font-size: 20px;
   color: #000;
   margin-right: auto;
}

/* Product options -> color */
.sticky-add-to-cart__color {
   padding-left: 35px;
   display: flex;
   align-items: center;

   p {
      margin: 0px 18px 0px 0px;
      padding: 0px;
      width: 20px;
      height: 20px;
      background: #000;
      border-radius: 100px;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
   }

   span {
      color: #000;
   }
}

/* Product options -> standard */
.sticky-add-to-cart__standard-option {
   padding-left: 35px;
   color: #000;
}

/* Quantity */
.sticky-add-to-cart__quantity {
   margin-right: 10px;
   margin-left: 58px;
   width: 140px;
   min-width: 140px;
   position: relative;
}

.sticky-add-to-cart-quantity__minus {
   display: block;
   position: absolute;
   top: 50%;
   left: 13px;
   transform: translateY(-50%);
   -webkit-transform: translateY(-50%);
   -moz-transform: translateY(-50%);
   color: #5e4d30;
   font-size: 16px;
}

.sticky-add-to-cart-quantity__input {
   display: block;
   width: 100%;
   text-align: center;
   padding: 12px 60px 12px 60px;
   border: 1px solid #d0d0d0;
   outline: none;
   font-size: 16px;
   color: #000;

   &:focus {
      border-color: #000;
   }
}

.sticky-add-to-cart-quantity__plus {
   display: block;
   position: absolute;
   top: 50%;
   right: 13px;
   transform: translateY(-50%);
   -webkit-transform: translateY(-50%);
   -moz-transform: translateY(-50%);
   color: #5e4d30;
   font-size: 16px;
}

/* Add to cart */
.sticky-add-to-cart__add-to-cart {
   width: 415px;

   .second-button {
      display: block;
      width: 100%;
      text-align: center;
      padding-top: 13px;
      padding-bottom: 13px;
      position: relative;

      &:before {
         content: '';
         display: block;
         position: absolute;
         top: 50%;
         left: 50%;
         width: 20px;
         height: 20px;
         background: url(loader.svg?v=3) top center no-repeat;
         background-size: cover;
         transform: translate(-50%, -50%);
         -webkit-transform: translate(-50%, -50%);
         -moz-transform: translate(-50%, -50%);
         opacity: 0;
         pointer-events: none;
         transition: all 0.3s ease-in;
         -webkit-transition: all 0.3s ease-in;
         -moz-transition: all 0.3s ease-in;
      }

      &.is-loading {
         color: rgba(255, 255, 255, 0);

         &:before {
            opacity: 1;
         }
      }
   }
}