/* ==========================================================================
   15.5 #HOME CLASSIC - HEADER
   ========================================================================== */
.header--type-6 {

   .header__promo-bar {
      background: #d8d2ba;
   }

   .header__container {
      padding-top: 50px;
      padding-bottom: 50px;
      border-color: #dbdbdb;

      @media (max-width: 991px) {
         padding-top: 19px;
         padding-bottom: 19px;
      }
   }

   .header__search {
      width: 275px;
      padding: 0px;
      margin: 0px;
      position: relative;

      @media (max-width: 991px) {
         display: none;
      }
   }

   .header-search__input {
      width: 100%;
      background: none;
      outline: none;
      border: none;
      padding-left: 30px;
      padding-bottom: 7px;
      border-bottom: 1px solid #dbdbdb;
      font-size: 16px;
   }

   .header-search__button {
      left: 0;
      top: 1px;
      position: absolute;
      display: block;
      background: none;
      border: none;
      padding: 0px;
      margin: 0px;
   }

   .header__logo {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);

      a {
         color: #685d33;
      }
   }

   .header__right {
      margin-left: auto;
   }

   .header__right > li > a span {
      background: #d8d2ba;
      color: #000;
   }

   .header__nav {
      position: relative;

      > li {

         &:first-child {
            margin-left: auto;

            .nav__item {

               &:after {
                  display: none;
               }
            }
         }

         &:last-child {
            margin-right: auto;
         }
      }
   }

   .nav__item {
      padding-top: 25px;
      padding-bottom: 25px;

      &:after {
         content: '';
         display: block;
         position: absolute;
         width: 4px;
         height: 4px;
         background: #cccccc;
         border-radius: 100px;
         -webkit-border-radius: 100px;
         -moz-border-radius: 100px;
         top: 50%;
         left: -34px;
         transform: translateY(-50%);
         -webkit-transform: translateY(-50%);
         -moz-transform: translateY(-50%);
         margin: 0px;
         opacity: 1;
         visibility: visible;

         @media (max-width: 1299px) {
            left: -27px;
         }

         @media (max-width: 1098px) {
            left: -20px;
         }
      }
   }
}