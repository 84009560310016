/* ==========================================================================
   12.6 #HOME MODERN - OUR JOURNAL
   ========================================================================== */
.modern-our-journal {
   padding-bottom: 180px;

   @media (max-width: 991px) {
      padding-bottom: 100px;
   }

   .row {
      margin: 0px -30px;

      > div {
         padding: 0px 30px;
      }
   }

   .blog-article {
      margin: 0px;

      @media (max-width: 991px) {
         margin: 40px 0px 0px 0px;
      }
   }

   .blog-article__title {
      line-height: 1.33;
      font-size: 30px;

      @media (max-width: 1098px) {
         font-size: 26px;
      }

      @media (max-width: 991px) {
         font-size: 20px;
      }
   }
}

.modern-our-journal__title {
   text-align: center;
   padding: 140px 0px 90px 0px;
   margin: 0px;

   @media (max-width: 991px) {
      padding: 65px 0px 0px 0px;
   }
}