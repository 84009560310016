/* ==========================================================================
   15.3 #HOME CLASSIC - COLLECTION BANNERS
   ========================================================================== */
.classic-collection-banners {
   padding-top: 95px;

   @media (max-width: 991px) {
      padding-top: 50px;
   }
}

.classic-collection-banner {
   margin: 20px 0px 0px 0px;
   display: flex;
   background: #e9ecf2;

   @media (max-width: 991px) {
      flex-wrap: wrap;
   }
}

.classic-collection-banner__image {
   width: calc(100% - #{400px});

   @media (max-width: 991px) {
      width: 100%;
   }

   img {
      height: 500px;
      display: block;
      width: 100%;
      object-fit: cover;

      @media (max-width: 991px) {
         height: 360px;
      }
   }
}

.classic-collection-banner__content {
   max-width: 400px;
   padding: 50px 55px;
   position: relative;

   @media (max-width: 991px) {
      width: 100%;
      order: 2;
      padding: 30px;
   }
}

.classic-collection-banner__tag {
   padding: 0px 0px 20px 0px;
   margin: 0px;
   color: #000;
   font-size: 14px;
   text-transform: uppercase;
   letter-spacing: 0.5px;

   @media (max-width: 991px) {
      font-size: 12px;
   }
}

.classic-collection-banner__title {
   color: #353368;
   padding: 0px 0px 20px 0px;
   margin: 0px;
}

.classic-collection-banner__description {
   font-size: 16px;
   line-height: 26px;

   @media (max-width: 991px) {
      font-size: 14px;
      line-height: 24px;
   }
}

.classic-collection-banner__action {
   position: absolute;
   left: 60px;
   bottom: 70px;
   font-size: 20px;

   @media (max-width: 991px) {
      left: auto;
      bottom: auto;
      position: relative;
      font-size: 17px;
      padding-top: 25px;
   }

   a {
      display: inline-block;
      color: #000;
      position: relative;
      padding-bottom: 10px;
      font-size: 20px;

      @media (max-width: 991px) {
         font-size: 17px;
      }

      &:hover {

         &:before {
            width: 100%;
         }
      }

      &:before {
         content: '';
         display: block;
         position: absolute;
         bottom: 0;
         left: 0;
         width: 50%;
         height: 2px;
         background: #000;
         transition: all 0.3s ease-in;
         -webkit-transition: all 0.3s ease-in;
         -moz-transition: all 0.3s ease-in;
      }
   }
}

.classic-collection-banner--type-2 {
   background: #f4f1ed;

   .classic-collection-banner__title {
      color: #685233;
   }
}