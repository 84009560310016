/* ==========================================================================
   15.9#HOME CLASSIC - OUR JOURNAL
   ========================================================================== */
.classic-our-journal {

   .row {
      margin: 0px -30px;

      > div {
         padding: 0px 30px;
      }
   }

   .our-journal__title {
      text-align: center;
      padding: 100px 0px 60px 0px;
      margin: 0px;
      font-size: 36px;

      @media (max-width: 991px) {
         font-size: 24px;
         padding-top: 70px;
         padding-bottom: 20px;
      }
   }

   .blog-article {
      margin: 0px;

      @media (max-width: 991px) {
         margin: 40px 0px 0px 0px;
      }
   }
}