/* ==========================================================================
   #SEARCH PAGE
   ========================================================================== */
.main-search {
   margin-top: -55px;
   position: relative;

   @media (max-width: 991px) {
      margin: -20px 0px 0px 0px;
   }
}