/* ==========================================================================
   19.4 #HOME SHOPPABLE - HEADER
   ========================================================================== */
.header--type-8 {

   .header__container {
      padding-top: 32px;
      padding-bottom: 32px;
      border-color: #e6e6e6;

      @media (max-width: 991px) {
         border: none;
         padding: 18px 0px;
      }
   }

   .header__right {
      margin-left: 0px;

      @media (max-width: 991px) {
         margin-left: auto;
      }
   }

   .header__cart {

      &:before {
         display: none !important;
      }

      span {
         left: 10px;
         right: auto;
         background: #000;
      }
   }

   .cart__total-price {
      position: relative;
      margin: 0px;
      padding: 0px;
      background: none !important;
      color: #000;
      border-radius: 0px;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      display: inline-block;
      width: auto;
      height: auto;
      font-size: 16px;
      margin-left: 15px;

      @media (max-width: 1199px) {
         display: none;
      }
   }

   .header__nav > li {
      margin: 0px 70px 0px 0px;
   }

   .nav__item {
      padding: 22px 0px;
   }

   .nav__item:after {
      display: none;
   }

   .mobile-menu__overlay {
      background: rgba(0, 0, 0, 0.5);
   }

   .mobile-menu__content {
      background: #fff;
   }

   .mobile-menu__close a {
      color: #000;
   }

   .mobile-menu__logo {
      color: #000;
   }

   .mobile-menu__nav > li > a {
      color: #000;
   }

   .mobile-menu__dropdown-btn {
      border-color: rgba(0, 0, 0, 0.5);
      color: rgba(0, 0, 0, 0.5);
   }

   .mobile-menu__nav > li {
      border-color: rgba(0, 0, 0, 0.1);
   }

   .mobile-menu__dropdown-menu li a {
      color: #000;
   }
}

.header__contact {
   margin-left: auto;
   margin-right: 90px;

   @media (max-width: 1199px) {
      margin-right: 45px;
   }

   @media (max-width: 991px) {
      display: none !important;
   }
}

.header__contact + .header__contact  {
   margin-left: 0px;
}

.header-contact__icon {
   min-width: 25px;
   width: 25px;
   margin-right: 25px;

   svg {
      width: 25px;
      height: 25px;
   }
}

.header-contact__content {
   line-height: 1.2;

   span {
      font-size: 12px;
      color: #999;
      text-transform: uppercase;
      letter-spacing: 0.5px;
   }

   p {
      padding: 5px 0px 0px 0px;
      margin: 0px;
      font-size: 16px;
      color: #000;
   }
}

.header__bottom-menu {
   position: relative;

   @media (max-width: 991px) {
      display: none;
   }
}

.header__switcher {
   float: right;
   position: relative;
   padding: 22px 0px 22px 0px;
   margin-left: 62px;

   @media (max-width: 991px) {
      display: none;
   }

   p {
      text-transform: uppercase;
      letter-spacing: 0.5px;
      color: #000;
      padding: 0px;
      margin: 0px;
      font-size: 16px;

      span {
         padding-left: 7px;

         &:before {
            content: "";
            display: inline-block;
            font: normal normal normal 1em/1 "LineIconsPro Light";
            speak: none;
            text-transform: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            display: inline-block;
            vertical-align: top;
            font-size: 16px;
            color: #000;
            transform: translateY(-50%) rotate(90deg);
            -webkit-transform: translateY(-50%) rotate(90deg);
            -moz-transform: translateY(-50%) rotate(90deg);
            pointer-events: none;
            top: 13px;
            position: relative;
         }
      }
   }

   select {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
   }
}