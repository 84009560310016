/* ==========================================================================
   8.16 #WISHLIST PAGE
   ========================================================================== */
.wishlist {
   padding: 90px 0px 160px 0px;

   @media (max-width: 991px) {
      padding: 60px 0px 80px 0px;
   }

   .container {

      .container {

         @media (max-width: 991px) {
            padding: 0px;
         }
      }
   }
}

/* Title */
.wishlist__title {
   font-size: 48px;
   text-align: center;
   padding: 0px 0px 80px 0px;
   margin: 0px;

   @media (max-width: 1098px) {
      font-size: 40px;
   }

   @media (max-width: 991px) {
      font-size: 26px;
      padding-bottom: 40px;
   }
}

/* Table */
.wishlist__table {
   width: 100%;

   thead {

      tr {

         th {
            padding: 10px 3px 10px 15px;
            color: #999999;
            font-size: 14px;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 0.5px;

            @media (max-width: 991px) {
               font-size: 13px;
            }

            &:first-child {
               padding-left: 0px;
            }

            &:last-child {
               padding-right: 5px;
            }
         }
      }
   }

   tbody {

      tr {

         td {
            border-top: 1px solid #dedede;
            padding: 20px 15px;

            &:first-child {
               padding-left: 0px;
            }

            &:last-child {
               padding-right: 5px;
            }
         }
      }
   }

   .sixth-button {
      white-space: nowrap;
   }

   .danger-button {
      color: red;
      border-color: red;
   }
}

/* Cart product */
.wishlist__product {
   display: flex;
   align-items: center;

   @media (max-width: 991px) {
      width: 400px;
   }
}

/* Cart product image */
.wishlist-product__image {
   width: 70px;
   min-width: 70px;

   a {
      display: block;
      position: relative;
      padding-bottom: 139.948%;

      img {
         display: block;
         width: 100%;
         height: 100%;
         left: 0;
         right: 0;
         bottom: 0;
         top: 0;
         position: absolute;
         object-fit: cover;
      }
   }
}

/* Title */
.wishlist-product__title {
   font-size: 16px;
   line-height: 1.3;
   margin-bottom: 0;
   letter-spacing: 0px;
   color: #000;
   font-family: "Pretendard";
   font-weight: 400;
   padding: 0px 0px 0px 20px;
   min-width: 140px;;

   @media (max-width: 991px) {
      font-size: 14px;
   }

   a {
      color: #000;
      letter-spacing: 0.5px;

      &:hover {
         color: #928656;
      }
   }
}

.wishlist-product__variant {
   font-size: 14px;
   color: #999999;
   padding: 0px 0px 0px 20px;

   @media (max-width: 991px) {
      font-size: 12px;
   }
}


/* Price */
.wishlist-product__price {
   font-size: 16px;
   line-height: 1.3;
   color: #000;

   @media (max-width: 991px) {
      font-size: 14px;
      width: 70px;
   }
}

/* Status */
.wishlist-product__status {
   white-space: nowrap;
   font-size: 16px;
   font-weight: bold;

   @media (max-width: 991px) {
      font-size: 14px;
   }
}

.wishlist-product__status--in-stock {
   color: #669900;
}

.wishlist-product__status--stock-out {
   color: #e73405;
}

.wishlist-product__status--pre-order {
   color: #000;
}

/* Delete */
.wishlist-product__delete {
   text-align: right;

   a {
      font-size: 12px;
      color: #000;
      line-height: 1.3;
   }
}