/* ==========================================================================
   18.4 #HOME MINIMAL - INSTAGRAM
   ========================================================================== */
.minimal-shop-by-the-look {

   .slick-list {
      margin: 0px -5px;

      .slick-slide {
         padding: 0px 5px;
      }
   }

   .slick-prev {
      display: none !important;
   }

   .slick-next {  
      display: none !important;
   }

   .slick-dots {
      text-align: center;
      margin: 90px 0px 0px 0px;
      padding: 0px;
      list-style: none;

      @media (max-width: 991px) {
         margin: 50px 0px 0px 0px;
      }

      li {
         display: inline-block;
         vertical-align: top;
         margin: 0px 10px;

         &.slick-active {

            button {
               width: 38px;
               border-radius: 40px;
               background-color: #216353;
            }
         }

         button {
            border: none;
            padding: 0px;
            margin: 0px;
            font-size: 0px;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            display: block;
            background: #cccccc;
            transition: 0.3s all ease-in-out;
            outline: none;
         }
      }
   }
}

.minimal-shop-by-the-look__container {
   padding: 148px 0px 125px 0px;

   @media (max-width: 991px) {
      padding: 70px 0px 60px 0px;
   }
}

.minimal-shop-by-the-look__title-and-action {

   @media (max-width: 991px) {
      flex-wrap: wrap;
      padding-bottom: 40px;
   }
}

.minimal-shop-by-the-look__title {
   padding: 0px 0px 70px 0px;
   margin: 0px;

   @media (max-width: 991px) {
      width: 100%;
      text-align: center;
      padding: 0px 0px 10px 0px;
   }
}

.minimal-shop-by-the-look__action {
   margin-left: auto;
   text-align: right;
   padding: 15px 0px 0px 30px;

   @media (max-width: 991px) {
      width: 100%;
      text-align: center;
      padding: 0px 0px 0px 0px;
   }

   span {
      font-weight: 600;
      color: #216353;
   }
}

/* Instagram feed */
.minimal-instagram-item {

   a {
      display: block;
      padding-bottom: 100%;
      position: relative;

      img {
         display: block;
         position: absolute;
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         height: 100%;
         width: 100%;
         object-fit: cover;
      }

      i {
         left: 0;
         right: 0;
         top: 50%;
         z-index: 3;
         color: #fff;
         opacity: 0;
         font-size: 48px;
         text-align: center;
         visibility: hidden;
         position: absolute;
         transform: translateY(-50%);
         margin-top: -30px;
         transition: 0.3s all ease-in-out;
      }

      &:after {
         top: 0;
         left: 0;
         opacity: 0;
         width: 100%;
         height: 100%;
         content: "";
         position: absolute;
         visibility: hidden;
         background-color: #000;
         transition: 0.3s all ease-in-out;
         z-index: 2;
      }

      &:hover {

         i {
            opacity: 1;
            margin-top: 0;
            visibility: visible;
         }

         &:after {
            opacity: 0.7;
            visibility: visible;
         }
      }
   }
}

