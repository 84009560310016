/* ==========================================================================
   #BODY
   ========================================================================== */
body {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 1.667;
  color: #666666;
  font-family: "Pretendard";
  -moz-osx-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;

  @media (max-width: 991px) {
    font-size: 14px;
    line-height: 24px;
  }
}

.home {
  background: #ececec;
}

.product-page {

  #main {
    overflow: visible;
  }
}

#root {
  overflow: hidden;
}

::selection {
  color: #ffffff;
  background-color: #928656;
}