/* ==========================================================================
   11.6 #FULL WIDTH OUR JOURNAL
   ========================================================================== */
.full-width-our-journal {
	padding: 110px 0px 145px 0px;

	@media (max-width: 991px) {
		padding: 65px 0px 110px 0px;
	}

	.row {
		margin: 0px -30px;

		@media (max-width: 1450px) {
			margin: 0px -20px;
		}

		> div {
			padding: 0px 30px;

			@media (max-width: 1450px) {
				padding: 0px 20px;
			}
		}
	}
}

.full-width-our-journal__title {
	font-size: 36px;
	padding: 0px 20px 0px 0px;
	margin: 0px;

   @media (max-width: 1199px) {
      font-size: 30px;
   }

   @media (max-width: 991px) {
      font-size: 24px;
      margin-bottom: 15px;
   }
}

.full-width-our-journal__action {
   margin-left: auto;
   text-align: right;

   @media (max-width: 991px) {
   	margin-bottom: 15px;
   }

   a {
      color: #000;
      letter-spacing: 0.75px;
      text-transform: uppercase;

      i {
         padding-left: 7px;
      }
   }
}

/* Post */
.full-width-post {
	margin-top: 60px;
	position: relative;

	@media (max-width: 991px) {
		margin-top: 20px;
	}

	&:hover {

		.full-width-post__content {
			opacity: 1;
			visibility: visible;
		}
	}
}

.full-width-post__image {

	img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.full-width-post__content {
	position: relative;
	z-index: 2;
	background: #fff;
	border: 1px solid #dadada;
	padding: 55px 55px 45px 45px;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease-in;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;

	@media (max-width: 991px) {
		padding: 35px 35px 30px 35px;
	}
}

.full-width-post__date {
	display: block;
	color: #666666;
	font-size: 13px;
	margin-bottom: 15px;
	height: 21px;
	overflow: hidden;
	text-transform: uppercase;
	letter-spacing: 0.5px;

	@media (max-width: 991px) {
		font-size: 11px;
		height: 18px;
		margin-bottom: 10px;
	}
}

.full-width-post__title {
	color: #000000;
	padding-bottom: 48px;
	margin: 0px;

	@media (max-width: 1199px) {
		padding-bottom: 30px;
	}

	a {
		display: block;
		height: 72px;
		overflow: hidden;
		color: #000;
		font-size: 30px;
		line-height: 1.2;

		@media (max-width: 991px) {
			font-size: 20px;
			line-height: 1.5;
			height: 60px;
		}
	}
}

.full-width-post__description {
	margin-bottom: 20px;
	height: 90px;
	overflow: hidden;

	@media (max-width: 991px) {
		font-size: 12px;
		line-height: 1.5;
		height: 54px;
		margin-bottom: 25px;
	}
}

.full-width-post__continue-link {

	a {
		display: inline-block;
		color: #000;
		font-size: 14px;
		font-weight: 600;
		position: relative;
		text-transform: uppercase;
		letter-spacing: 0.5px;

		@media (max-width: 991px) {
			font-size: 11px;
		}

		&:after {
			left: 0;
			content: "";
			height: 2px;
			width: 100%;
			bottom: -2px;
			position: absolute;
			background-color: #000;
		}
	}
}