/* ==========================================================================
   18.6 #HOME MINIMAL OUR PRODUCTS
   ========================================================================== */
.minimal-our-products {
   padding: 120px 0px 90px 0px;
   overflow: hidden;

   @media (max-width: 991px) {
      padding: 60px 0px 50px 0px;
   }

   .product-grid-item {
      margin-bottom: 60px;

      @media (max-width: 991px) {
         margin-bottom: 30px;
      }
   }

   .our-products__title {
      padding: 0px 0px 70px 0px;
      margin: 0px;

      @media (max-width: 991px) {
         padding-bottom: 40px;
         text-align: center;
      }
   }

   .our-products__title-and-action {

      .our-products__title {

         @media (max-width: 991px) {
            width: 100%;
         }
      }

      .product-tabs__action {
         padding-top: 10px;
         padding-left: 20px;

         @media (max-width: 991px) {
            display: none;
         }
      }
   }

   .col-6 {

      @media (max-width: 991px) {

         .product-grid-item__colors {
            margin-top: 10px;

            .product-grid-item__color {
               width: 8px;
               height: 8px;
            }
         }

         .product-grid-item__image-colors {
            padding-top: 15px;

            li {
               width: 28px;
               margin: 5px 5px 0px 0px;
            }
         }

         .product-grid-item__sizes {
            padding-top: 10px;
         }

         .product-grid-item__size {
            min-width: 26px;
            min-height: 26px;
            font-size: 9px;
            line-height: 1.667;
            padding: 5px 5px;
         }

         .product-grid-item__tag {
            left: 11px;
            top: 14px;
            font-size: 9px;
            padding: 4px 9px;
         }

         .product-grid-item__feature {
            font-size: 10px;
            padding: 15px 0px 0px 0px;
         }

         .product-grid-item__name {
            margin-top: 15px;
            font-size: 14px;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
         }

         .product-grid-item__price {
            padding-top: 3px;
         }
      }
   }
}