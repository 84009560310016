/* ==========================================================================
   15.6 #HOME CLASSIC - INTRO TEXT
   ========================================================================== */
.classic-intro-text {
   margin: 110px 0px 0px 0px;
   text-align: center;
   padding: 0px;

   @media (max-width: 991px) {
      margin-top: 65px;
   }
}

.classic-intro-text__text {
   padding: 0px;
   margin: 0px auto;
   font-size: 24px;
   line-height: 44px;
   max-width: 1025px;

   @media (max-width: 1098px) {
      font-size: 22px;
      line-height: 40px;
   }

   @media (max-width: 991px) {
      font-size: 17px;
      line-height: 1.8;
   }
}