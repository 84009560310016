/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #666666;
  text-decoration: none;
  transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  cursor: pointer;
  
  &:hover {
    color: #000000;
  }
}