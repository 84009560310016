/* ==========================================================================
   17.1 #HOME SIDEBAR CATEGORIES
   ========================================================================== */
.sidebar-categories {
   margin: 0px;

   > div {
      padding: 0px;
   }

   .shop-category {
      margin: 0px;
   }
}